import AppDispatcher from '../Dispatcher'
import $ from '../WebUtils'
import Constants from '../Constants'
import request = require('superagent')

const AccessTokenActionCreators = {
  async fetchTokens(companyName?: string, siteName?: string) {
    return Promise.all([
      new Promise((resolve, reject) => {
        $.getAccessToken(companyName, { site: siteName }, (err, res) => {
          if (err) {
            return reject(err)
          }
          resolve(res.body.accessToken)
        })
      }),
      new Promise((resolve, reject) => {
        $.getAccessToken(
          companyName,
          { site: siteName, permissions: [Constants.PERMISSIONS.SITE_VIEW.name] },
          (err, res) => {
            if (err) {
              return reject(err)
            }
            resolve(res.body.accessToken)
          }
        )
      })
    ])
  },
  async getAccessToken(companyName?: string, siteName?: string) {
    try {
      const [jwt, siteViewJwt] = await AccessTokenActionCreators.fetchTokens(companyName, siteName)
      AppDispatcher.handleServerAction({
        type: Constants.ActionTypes.ACCESS_TOKEN_RECEIVED,
        jwt,
        siteViewJwt
      })
    } catch (e) {
      AppDispatcher.handleServerAction({
        type: Constants.ActionTypes.ACCESS_TOKEN_RECEIVED,
        jwt: null,
        siteViewJwt: null,
        error: e
      })
    }
  },

  login(username: string, password: string, cb: (err: Error, res: request.Response) => void) {
    $.loginByService(username, password, (err, res) => {
      AppDispatcher.handleServerAction({
        type: Constants.ActionTypes.ACCESS_TOKEN_RECEIVED,
        jwt: err ? {} : res.body.accessToken,
        error: err
      })

      cb(err, res)
    })
  }
}

export default AccessTokenActionCreators
