import * as _ from 'lodash'
import logger from '../libs/logger/Logger'
import BaseStore from './BaseStore'
import AppDispatcher from '../Dispatcher'
import Constants from '../Constants'
import * as jwtDecode from 'jwt-decode'
import { inflate } from 'pako/lib/inflate'
import * as base64 from 'base64-js'

type Permission = string
interface IAcl {
  systemLevel: Permission[]
  customerLevel: { [key: string]: string }
  siteLevel: { [key: string]: string }
}
interface IAccessTokenPayload {
  ACL: IAcl
  user: User
}
let error: Error | null = null
let accessToken: string | null = null
let jwt: string | null = null
let siteViewJwt: string | null = null

let ACL: IAcl | null = null
let user: User | null = null

class AccessTokenStore extends BaseStore {
  getData() {
    return {
      accessToken,
      jwt,
      siteViewJwt,
      ACL,
      user,
      error
    }
  }

  updateAccessToken(newJWT: string, newSiteViewJwt: string) {
    // in case of error keep using old token until successful renewal
    if (_.isEmpty(newJWT) || error) {
      return
    }
    jwt = newJWT
    siteViewJwt = newSiteViewJwt

    const decoded = decodeJWT(jwt)
    accessToken = decoded.accessToken
    const payload = decoded.payload

    ACL = payload.ACL
    user = payload.user
    logger.setExtraContext({ accessToken })
  }

  dispatcherIndex = AppDispatcher.register(payload => {
    const action = payload.action

    switch (action.type) {
      case Constants.ActionTypes.ACCESS_TOKEN_RECEIVED:
        error = action.error
        this.updateAccessToken(action.jwt, action.siteViewJwt)
        this.emitChange()
        break
    }
  })
}

export function decodeJWT(jwt: string): { accessToken: string; payload: IAccessTokenPayload } {
  const accessToken = jwtDecode(jwt)

  let payload = accessToken.payload
  if (accessToken.deflated) {
    payload = base64.toByteArray(accessToken.payload)
    payload = JSON.parse(inflate(payload, { to: 'string' }))
  }
  return {
    accessToken,
    payload
  }
}

export default new AccessTokenStore()
