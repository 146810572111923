import '@babel/polyfill'
import Logger from 'logger'
import React from 'react'
import App from './components/app/App'
import ReactDOM from 'react-dom'

if (Logger.wrap) {
  Logger.wrap(renderApp)
} else {
  renderApp()
}

function renderApp() {
  ReactDOM.render(<App />, document.getElementById('main'))
}
