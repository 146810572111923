import * as Sentry from '@sentry/browser'
import { BrowserTracing } from '@sentry/tracing'

import { omit } from 'lodash'
import { ILogger } from './Logger'

export default class SentryLogger implements ILogger {
  static create({
    sessionId = '',
    DSN = '',
    release = ''
  }: { DSN?: string; release?: string; sessionId?: string } = {}) {
    Sentry.init({
      dsn: DSN,
      release,
      integrations: [new BrowserTracing({})]
    })
    return new SentryLogger(sessionId)
  }

  constructor(private sessionId: string) {
    Sentry.setTag('sessionId', this.sessionId)
    window.addEventListener('error', e => this.exception(e.error))
    window.addEventListener('unhandledrejection', e => this.exception(e.reason))
  }

  log(message: string, payload: object = {}) {
    payload = normalize(payload)
    this.setExtraContext(payload)
    Sentry.captureMessage(message, 'log')
  }

  info(message: string, payload: object = {}) {
    payload = normalize(payload)
    this.setExtraContext(payload)
    Sentry.captureMessage(message, 'info')
  }

  warn(message: string, payload: object = {}) {
    payload = normalize(payload)
    this.setExtraContext(payload)
    Sentry.captureMessage(message, 'warning')
  }

  error(message: string, payload: object = {}) {
    payload = normalize(payload)
    this.setExtraContext(payload)
    Sentry.captureMessage(message, 'error')
  }

  exception(exception: Error, payload: object = {}) {
    payload = normalize(payload)
    this.setExtraContext(payload)
    Sentry.captureException(exception)
  }

  failedRequest(message: string, request: any, response: any, payload?: object) {
    this.error(message, {
      req: normalizeRequest(request),
      res: normalizeResponse(response),
      ...payload
    })
  }

  setUserContext(userInfo: User) {
    Sentry.setUser(userInfo)
  }

  setExtraContext(contextData: object) {
    Sentry.setContext('extra', contextData)
  }

  captureBreadcrumb(message: string, data: object) {
    Sentry.addBreadcrumb({ message, data })
  }
}

function normalize(payload: any) {
  if (payload instanceof Error) {
    return { ...payload }
  }

  return payload
}

function normalizeResponse(response: any) {
  return {
    body: filterBody(response.body),
    status: response.status
  }
}

function normalizeRequest(request: any) {
  return {
    body: filterBody(request.body),
    url: request.url
  }
}

function filterBody(body: any) {
  return omit(body, (val, key) => /pass/gi.test(key))
}
