import i18n from 'i18n'

export function decodeCustomerTypeName(customerTypeCode) {
  if (!customerTypeCode) {
    return ''
  }

  try {
    return i18n.getIntlMessage(`customer-types.${customerTypeCode}`)
  } catch (err) {
    console.error(err)
    return customerTypeCode
  }
}

export function decodeTitle(titleCode) {
  if (!titleCode) {
    return ''
  }

  try {
    return i18n.getIntlMessage(`user-titles.${titleCode}`)
  } catch (err) {
    console.warn(`Can't translate title ${titleCode}  `)
    return titleCode
  }
}
