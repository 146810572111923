export const setSafeTimeout = function (cb, delay, interval = 1000) {
  var initTime = Date.now()
  var intervalID

  return (intervalID = setInterval(() => {
    if (Date.now() - initTime >= delay) {
      clearInterval(intervalID)
      cb()
    }
  }, interval))
}

export const clearSafeTimeout = function (timeoutID) {
  clearInterval(timeoutID)
}
