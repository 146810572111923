import Dispatcher from '../../Dispatcher'
import $ from '../../WebUtils'
import Constants from '../../Constants'

export const fetchRenamings = (function fetchRenamings() {
  let request
  return async function (filter: string, limit = 10) {
    if (request && request.abort) {
      request.abort()
    }

    Dispatcher.handleServerAction({
      type: Constants.ActionTypes.RENAMINGS_FETCHING
    })

    let res
    try {
      request = $.fetchRenamings(filter, limit)
      res = await request
    } catch (err) {
      console.error(err)
      alert(`Failed to fetch renamings: ${err.message}`)
    }

    res &&
      Dispatcher.handleServerAction({
        type: Constants.ActionTypes.RENAMINGS_FETCHED,
        renamings: res.body.renamings
      })
  }
})()
