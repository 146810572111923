import React from 'react'
import Griddle from 'griddle-react'
import { decodeTitle } from '../../../libs/customer-type-decoder'

class UsersTable extends React.Component {
  static displayName = 'UsersTable'

  static defaultProps = {
    users: [],
    onUserClick: () => {}
  }

  onUserClick = row => {
    this.props.onUserSelect(row.props.data)
  }

  render() {
    const { companies } = this.props
    var results = this.props.users.map(user => {
      user.name = user.name || ''
      user._title = decodeTitle(user.title) || ''
      user.lastActivity = user.lastLoginAt ? new Date(user.lastLoginAt) : new Date(1970)
      user.createdDate = new Date(user.createdAt)

      if (user.company) {
        const company = _.find(companies, c => c.id === user.company.id)
        user._company = company ? company.name : '-'
      } else {
        user._company = '-'
      }

      return user
    })

    return (
      <Griddle
        results={results}
        useCustomFilterer
        customFilterer={customFilterFunction}
        filterPlaceholderText='Filter Results by Company or Email or Name'
        gridClassName='users-griddle'
        showFilter={true}
        onRowClick={this.onUserClick}
        resultsPerPage={30}
        initialSort='lastActivity'
        columnMetadata={[
          { columnName: 'name', customComponent: NameCell, displayName: 'Name' },
          { columnName: 'email', displayName: 'Email' },
          { columnName: '_title', displayName: 'Title' },
          { columnName: '_company', displayName: 'Company' },
          {
            columnName: 'lastActivity',
            customComponent: LastActivityCell,
            displayName: 'Last activity',
            customCompareFn: (a, b) => {
              return b.getTime() - a.getTime()
            }
          },
          {
            columnName: 'createdDate',
            customComponent: CreatedDateCell,
            displayName: 'Created at',
            customCompareFn: (a, b) => {
              return b.getTime() - a.getTime()
            }
          }
        ]}
        columns={['name', 'email', '_title', '_company', 'lastActivity', 'createdDate']}
      />
    )
  }
}

function customFilterFunction(users, query) {
  const lowerCaseQuery = query.toLowerCase()
  const includes = targetString => (targetString || '').toString().toLowerCase().includes(lowerCaseQuery)

  return users.filter(user => {
    const name = user.name || ''
    const lastname = user.lastname || ''
    const fullName = `${name} ${lastname}`
    const reverseFullName = `${lastname} ${name}`
    const nameMatch = includes(fullName) || includes(reverseFullName)
    const emailMatch = includes(user.email)
    const companyMatch = includes(user._company)

    return nameMatch || emailMatch || companyMatch
  })
}

class NameCell extends React.Component {
  static displayName = 'NameCell'

  render() {
    var user = this.props.rowData
    const lastname = user.lastname || ''
    const name = user.name || ''

    return <span>{lastname + ' ' + name}</span>
  }
}

class LastActivityCell extends React.Component {
  static displayName = 'LastActivityCell'

  render() {
    var user = this.props.rowData
    return (
      <span>
        {user.lastActivity && user.lastActivity.getFullYear() != 1970 ? user.lastActivity.toLocaleString() : '-'}
      </span>
    )
  }
}

class CreatedDateCell extends React.Component {
  static displayName = 'CreatedDateCell'

  render() {
    var user = this.props.rowData
    return <span>{user.createdDate.toLocaleDateString()}</span>
  }
}

export default UsersTable
