import React from 'react'
import CompaniesPanelActions from '../actions/CompaniesPanelActionCreators'
import PropTypes from 'prop-types'
import TitledSelect from '../../TitledSelect'
import { decodeCustomerTypeName } from '../../../libs/customer-type-decoder'
import Throbber from '../../Throbber'
import Constants from '../../../Constants'
import CompaniesPanelStore from '../stores/CompaniesPanelStore'
import trackAction from '../../../libs/analytics/ActionTracking'

class NewCompanyForm extends React.Component {
  static displayName = 'NewCompanyForm'

  static propTypes = {
    customerTypes: PropTypes.array.isRequired,
    loading: PropTypes.bool
  }

  constructor(props) {
    super(props)
    this.state = {
      companyName: '',
      type: null,
      isAccountHolder: true
    }
  }

  componentDidMount = () => {
    CompaniesPanelStore.addListener(
      Constants.ActionTypes.companiesPanelActions.COMPANY_CREATED,
      this.handleCompanyCreated
    )
  }

  componentWillUnmount = () => {
    CompaniesPanelStore.removeListener(
      Constants.ActionTypes.companiesPanelActions.COMPANY_CREATED,
      this.handleCompanyCreated
    )
  }

  componentDidUpdate() {
    if (this.state.type === null && this.props.customerTypes.length === 1) {
      // select first type by default if it's the only one option
      this.setState({ type: this.props.customerTypes[0].key })
    }
  }

  handleCompanyCreated = () => {
    this.setState({ companyName: '', type: null })
  }

  onEditCompanyChange = e => {
    this.setState({ companyName: e.target.value })
  }

  isValid = () => {
    return this.state.companyName && this.state.type
  }

  onCompanyTypeChange = type => {
    this.setState({ type })
  }

  onAddCompanyClick = e => {
    e.preventDefault()
    trackAction('Create customer button is clicked')
    if (this.isValid()) {
      const { companyName, type, isAccountHolder } = this.state
      CompaniesPanelActions.createCompany({
        name: companyName,
        type,
        isAccountHolder
      })
    }
  }

  render() {
    return (
      <form className='new-company-form'>
        <Throbber show={this.state.loading}>
          <h3>Add new customer</h3>
          <div className='form-group'>
            <label>Company name</label>
            <input
              type='text'
              className='form-control'
              value={this.state.companyName}
              onChange={this.onEditCompanyChange}
            />
          </div>

          <div className='form-group'>
            <TitledSelect title='Select customer type' onChange={this.onCompanyTypeChange} value={this.state.type}>
              {this.props.customerTypes.map(ct => (
                <option value={ct.key} key={ct.key}>
                  {decodeCustomerTypeName(ct.key)}
                </option>
              ))}
            </TitledSelect>
          </div>
          <div className='checkbox'>
            <label>
              <input
                type='checkbox'
                checked={this.state.isAccountHolder}
                onChange={e => this.setState({ isAccountHolder: e.target.checked })}
              />{' '}
              is Account Holder
            </label>
          </div>
          {
            <div className='form-group'>
              <button
                type='submit'
                disabled={!this.isValid()}
                className='btn btn-default'
                onClick={this.onAddCompanyClick}
              >
                Create customer
              </button>
            </div>
          }
        </Throbber>
      </form>
    )
  }
}

export default NewCompanyForm
