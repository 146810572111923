import * as React from 'react'
import { classes } from 'typestyle'
import './Throbber.less'

const ThrobberImg = require('../../images/throbber.gif')
const ThrobberMiniImg = require('../../images/mini-throbber.gif')

interface ThrobberInterface {
  show?: boolean
  mini?: boolean
  className?: string
  message?: string
  description?: string
}
class Throbber extends React.Component<ThrobberInterface, {}> {
  static displayName = 'Throbber'

  render() {
    return (
      <div
        className={classes(
          'throbber-container',
          this.props.show ? 'show-throbber' : '',
          this.props.mini ? 'mini' : '',
          this.props.className
        )}
      >
        {this.props.show && (
          <div className='throbber-shown-container'>
            <img src={this.props.mini ? ThrobberMiniImg : ThrobberImg} className='throbber-image' alt='throbber' />
            <div className='throbber-message'>{this.props.message}</div>
            <div className='throbber-description'>{this.props.description}</div>
          </div>
        )}

        <div className='throbber-content'>{this.props.children}</div>
      </div>
    )
  }
}

export default Throbber
