import Dispatcher from '../../../Dispatcher'
import $ from '../../../WebUtils'
import Constants from '../../../Constants'
import async from 'async'

export default {
  fetchRolesAndPermissions() {
    async.parallel([$.getRoles, $.getPermissions], (err, res) => {
      Dispatcher.handleServerAction({
        type: Constants.ActionTypes.FETCH_ROLES_AND_PERMISSIONS,
        roles: res[0].body.roles,
        permissions: res[1].body.permissions
      })
    })
  }
}
