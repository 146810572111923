import * as React from 'react'

interface ITitledSelectProps {
  title: string
  onChange: (value: string) => void
  value: string | null
  className?: string
  readonly?: boolean
}

class TitledSelect extends React.Component<ITitledSelectProps> {
  static displayName = 'TitledSelect'

  static defaultProps = {
    title: ''
  }

  onChange = e => {
    let value = e.target.value
    if (value === this.props.title) {
      value = null
    }
    this.props.onChange(value)
  }

  render() {
    const { readonly } = this.props
    const value = this.props.value == null ? this.props.title : this.props.value

    return (
      <select
        className={this.props.className ? this.props.className + ' form-control' : 'form-control'}
        value={value}
        onChange={this.onChange}
        disabled={readonly}
      >
        <option>{this.props.title}</option>
        {this.props.children}
      </select>
    )
  }
}

export default TitledSelect
