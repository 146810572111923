import PropTypes from 'prop-types'
import React from 'react'
import { Alert } from 'react-bootstrap'
import _ from 'lodash'
import SitesRolesTable from './roles_table/SitesRolesTable'
import SiteRolesRow from './roles_table/SiteRolesRow'
import RolesPanelActionCreators from '../actions/RolesPanelActionCreators'
import Throbber from '../../Throbber'

import Constants from '../../../Constants'
import CompanySelectionInput from '../../CompanySelectionInput'

class UserRegistrationForm extends React.Component {
  static displayName = 'UserRegistrationForm'

  static propTypes = {
    companies: PropTypes.array,
    roles: PropTypes.array,
    onUserCreate: PropTypes.func,
    onUserRegisterWindowHide: PropTypes.func,
    errMsg: PropTypes.string
  }

  static defaultProps = {
    companies: [],
    roles: [],
    errMsg: '',
    langs: Constants.LOCALES
  }

  state = {
    editUser: {
      company: null,
      role: {},
      lang: this.props.lang || Constants.DEFAULT_LOCALE,
      email: ''
    },
    fetchingSites: false,
    validationErrors: {
      email: '',
      company: '',
      accountHolder: '',
      role: ''
    },
    sitePoliciesRoles: []
  }

  onAddUserClick = e => {
    e.preventDefault()
    const validationErrors = this.getValidationErrors()
    const isValid = Object.values(validationErrors).length === 0

    this.setState({
      validationErrors
    })

    if (!isValid) {
      return
    }

    const roles = { customers: [this.state.editUser.role] }
    const userData = { ...this.state.editUser, roles }
    delete userData.role
    this.props.onUserCreate(userData, this.state.sitePoliciesRoles)
  }

  handleEditUserChange = (field, e) => {
    var editUser = this.state.editUser
    editUser[field] = e.target.value
    this.forceUpdate()
  }

  handleEditUserChangeCompany = company => {
    const companyId = company ? company.id : null
    var editUser = this.state.editUser

    editUser.company = companyId
    editUser.companyName = company.name
    this.forceUpdate()
  }

  handleEditUserChangeAccountHolder = async ({ id: accountHolderId }) => {
    if (accountHolderId) {
      this.state.editUser.role = { customer: accountHolderId, roles: [], sites: [] }
      if (!this.getAccountHolderSites().length) {
        this.setState({ fetchingSites: true })
        await RolesPanelActionCreators.fetchCustomerSites(accountHolderId)
        this.setState({ fetchingSites: false })
      }
    } else {
      this.state.editUser.role = {}
    }
    this.forceUpdate()
  }

  handleSiteRoleChanged = (site, checked, role) => {
    const siteRole = this.state.editUser.role.sites.find(s => s.site === site.id)
    if (checked) {
      if (siteRole) {
        siteRole.roles.push(role.id)
      } else {
        this.state.editUser.role.sites.push({ site: site.id, roles: [role.id] })
      }
    } else {
      siteRole.roles = siteRole.roles.filter(r => r !== role.id)
      if (siteRole.roles.length === 0) {
        this.state.editUser.role.sites = this.state.editUser.role.sites.filter(s => s.site !== siteRole.site)
      }
    }
    this.forceUpdate()
  }

  handleSiteUserPolicyChanged = (policyAssigned, roleName) => {
    let { sitePoliciesRoles } = this.state

    if (policyAssigned) {
      sitePoliciesRoles.push(roleName)
    } else {
      sitePoliciesRoles = sitePoliciesRoles.filter(r => r !== roleName)
    }
    this.setState({ sitePoliciesRoles })

    this.getAccountHolderSites().forEach(site => {
      this.handleSiteRoleChanged(site, policyAssigned, { id: roleName })
    })
  }

  getValidationErrors = () => {
    const errors = {}

    if (!this.isValidUserEmail()) {
      errors.email = 'Please, enter an email'
    }

    if (!this.isValidRole()) {
      errors.role = 'Please, set correct role'
    }

    if (!this.isValidCompany()) {
      errors.company = 'Please, select a Company'
    }

    if (!this.isValidAccountHolder()) {
      errors.accountHolder = 'Please, select an Account Holder'
    } else if (!this.isAccountHolderHasSites()) {
      errors.accountHolder = 'Please, select an account holder which has at least one site'
    }

    return errors
  }

  isValidUserEmail = () => {
    return this.state.editUser.email
  }

  isValidAccountHolder = () => {
    return !_.isEmpty(this.state.editUser.role)
  }

  isValidCompany = () => {
    return !_.isEmpty(this.state.editUser.company)
  }

  isValidRole() {
    const role = this.state.editUser.role
    return role.customer && (role.roles.length > 0 || role.sites.length > 0)
  }

  isAccountHolderHasSites() {
    const accountHolderSites = this.getAccountHolderSites()
    return !this.state.fetchingSites && this.state.editUser.role.customer && accountHolderSites.length > 0
  }

  getAccountHolderSites = () => {
    var currentCompany = _.find(this.props.companies, s => s.id === this.state.editUser.role.customer)
    if (!currentCompany) {
      return []
    }
    return _.clone(currentCompany.constructionSites) || []
  }

  renderInvalidAlert() {
    const { validationErrors } = this.state
    const alertMessage =
      validationErrors.email ||
      validationErrors.company ||
      validationErrors.accountHolder ||
      validationErrors.role ||
      this.props.errMsg

    if (!alertMessage) {
      return null
    }

    return <Alert bsStyle='danger'>{alertMessage}</Alert>
  }

  render() {
    const { roles } = this.props
    const accountHolderSites = this.getAccountHolderSites()
    const InvalidAlert = this.renderInvalidAlert()

    const { editUser } = this.state

    return (
      <div className='reg-form'>
        <form className='form-inlinex new-role-form' role='form'>
          <div className=' panel panel-default'>
            <div className='panel-heading'>Authorization information</div>
            <div className='panel-body'>
              <div className='form-group'>
                <label>Email</label>
                <input
                  type='text'
                  value={this.state.editUser.email}
                  onChange={this.handleEditUserChange.bind(this, 'email')}
                  className='form-control'
                  placeholder='Email'
                />
              </div>
            </div>
          </div>
          <div className='panel panel-default'>
            <div className='panel-heading'>Personal info</div>
            <div className='panel-body'>
              <div className='form-group'>
                <label>
                  Company <i>(Who the User Works for)</i>
                </label>
                <CompanySelectionInput
                  onChange={this.handleEditUserChangeCompany}
                  companies={this.props.companies}
                  companyId={editUser.company}
                />
              </div>
              <div className='form-group'>
                <label>
                  Account Holder <i>(Company that holds the Project Account)</i>
                </label>

                <CompanySelectionInput
                  onChange={this.handleEditUserChangeAccountHolder}
                  companies={this.props.companies}
                  companyId={editUser.role.customer}
                />
              </div>
              <Throbber show={this.state.fetchingSites}>
                {accountHolderSites.length > 0 && (
                  <SitesRolesTable
                    roles={roles}
                    sites={accountHolderSites}
                    sitePolicies={{ roles: this.state.sitePoliciesRoles }}
                    onSiteUserPolicyChange={this.handleSiteUserPolicyChanged}
                    sitesRoles={this.state.editUser.role.sites || []}
                    starRoles={[]}
                    renderSiteRolesRow={({ siteRoles, site, roles }) => {
                      return (
                        <SiteRolesRow
                          site={site}
                          siteRoles={siteRoles}
                          roles={roles}
                          pending={false}
                          readonlyRoles={[]}
                          onRoleChanged={(checked, role) => this.handleSiteRoleChanged(site, checked, role)}
                        />
                      )
                    }}
                  />
                )}
              </Throbber>
            </div>
          </div>
          {InvalidAlert}
          <button type='submit' className='btn btn-default' onClick={this.onAddUserClick}>
            Sign up
          </button>
        </form>
      </div>
    )
  }
}

export default UserRegistrationForm
