import React from 'react'
import UserRolesTableRow from './UserRolesTableRow'

class UserRolesTable extends React.Component {
  static displayName = 'UserRolesTable'

  static defaultProps = {
    user: {},
    roles: [],
    newlyCreatedCustomerRole: null
  }

  state = {
    uncollapsedRowCustomerIds: new Set()
  }

  componentDidUpdate(prevProps) {
    const { newlyCreatedCustomerRole } = this.props
    if (newlyCreatedCustomerRole && newlyCreatedCustomerRole !== prevProps.newlyCreatedCustomerRole) {
      this.updateUncollapsedRows(false, newlyCreatedCustomerRole.customer)
    }
  }

  handleRowToggled = customerId => {
    const collapsed = this.state.uncollapsedRowCustomerIds.has(customerId)
    this.updateUncollapsedRows(collapsed, customerId)
  }

  updateUncollapsedRows = (collapsed, customerId) => {
    if (collapsed) {
      this.setState(state => {
        state.uncollapsedRowCustomerIds.delete(customerId)
        return state
      })
    } else {
      this.setState(state => {
        state.uncollapsedRowCustomerIds.add(customerId)
        return state
      })
    }
  }

  render() {
    const { user } = this.props
    const { customers: customersRoles } = user.roles
    if (!user.roles) {
      return <div />
    }

    const { uncollapsedRowCustomerIds } = this.state
    return (
      <table className='table table-condensed table-striped roles-table'>
        <tbody>
          {customersRoles
            .filter(customersRole => customersRole.customer !== '*')
            .map(customerRole => {
              return (
                <UserRolesTableRow
                  key={customerRole.customer + user.email}
                  customerId={customerRole.customer}
                  roles={this.props.roles}
                  userRole={customerRole}
                  user={user}
                  companies={this.props.companies}
                  collapsed={!uncollapsedRowCustomerIds.has(customerRole.customer)}
                  onToggle={this.handleRowToggled}
                />
              )
            })}
        </tbody>
      </table>
    )
  }
}

export default UserRolesTable
