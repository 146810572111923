import BaseStore from './BaseStore'
import UserStore from './UserStore'
import * as moment from 'moment'
import { LocaleSpecification } from 'moment'
import 'moment/locale/ru'
import AppDispatcher from '../Dispatcher'
import Constants from '../Constants'
import localizationData from '../i18n/index'

export enum Language {
  ru = 'ru',
  en = 'en'
}

let userlang = navigator.language.slice(0, 2)

const langs: string[] = [Language.ru, Language.en]
const langIndex = langs.indexOf(userlang)

if (langIndex === -1) {
  userlang = Language.en
} else {
  userlang = langs[langIndex]
}

let selectedLanguage = userlang
setWidgetsCulture(selectedLanguage)

class LocaleStore extends BaseStore {
  getData() {
    return { localizationData: localizationData[selectedLanguage], selectedLanguage }
  }

  getIntlMessage(messagePath: string): string {
    const messages = localizationData[selectedLanguage].messages

    const path = messagePath.split('.')
    let message = messages

    path.forEach(subPath => {
      if (!(subPath in message)) {
        throw new Error(`${messagePath} not found`)
      }

      message = message[subPath]
    })

    return message
  }

  dispatcherIndex = AppDispatcher.register(payload => {
    const action = payload.action

    switch (action.type) {
      case Constants.ActionTypes.APP_USER_UPDATED:
      case Constants.ActionTypes.LOGIN_SUCCESS:
        AppDispatcher.waitFor([UserStore.dispatcherIndex])
        selectedLanguage = UserStore.getData().userInfo.lang || userlang
        setWidgetsCulture(selectedLanguage)
        this.emitChange()
        break
    }
  })
}

export function getDateFormat() {
  return selectedLanguage === 'en' ? 'MM/DD/YYYY' : 'DD.MM.YYYY'
}

export function getLocale() {
  return selectedLanguage === 'en' ? 'uk' : 'ru'
}

moment.updateLocale('en', {
  longDateFormat: {
    L: 'MM/DD/YY'
  }
} as Partial<LocaleSpecification>)

moment.updateLocale('ru', {
  longDateFormat: {
    L: 'DD.MM.YY'
  }
} as Partial<LocaleSpecification>)

function setWidgetsCulture(language: string) {
  moment.locale(language)
}

export default new LocaleStore()
