import _ from 'lodash'
import Constants from '../Constants'
import { clearSafeTimeout, setSafeTimeout } from '../libs/timeout'
import LoginServiceActionCreators from '../actions/LoginServiceActionCreators'
import AccessTokenStore from '../stores/AccessTokenStore'

var timeout

let errorCount = 0

var getCurrentCompanyNameMethod = () => null
var getCurrentSiteNameMethod = () => null

class AccessTokenRenewalService {
  static listenToStoreChanges(getCompanyNameMethod, getSiteNameMethod) {
    getCurrentCompanyNameMethod = getCompanyNameMethod || getCurrentCompanyNameMethod
    getCurrentSiteNameMethod = getSiteNameMethod || getCurrentSiteNameMethod
    AccessTokenStore.addChangeListener(AccessTokenRenewalService._onStoreChange)
  }

  static _onStoreChange() {
    if (timeout) {
      clearSafeTimeout(timeout)
    }

    var { accessToken, error } = AccessTokenStore.getData()

    if (error || _.isEmpty(accessToken)) {
      errorCount++
      if (errorCount > Constants.ACCESS_TOKEN_MAX_ERROR_COUNT) {
        AccessTokenRenewalService.crashExit()
      }
      return AccessTokenRenewalService.scheduleEmergencyRenewal()
    }

    errorCount = 0
    AccessTokenRenewalService.schedulePlanRenewal(accessToken)
  }

  static crashExit() {
    location.reload()
  }

  static scheduleEmergencyRenewal() {
    timeout = setSafeTimeout(
      AccessTokenRenewalService.renewAccessToken,
      Constants.ACCESS_TOKEN_RENEWAL_AFTER_ERROR_TIME
    )
  }

  static schedulePlanRenewal(accessToken) {
    var { exp, iat } = accessToken
    // ask new access token for a several minutes before expiration
    var nextRequestTimeout = (exp - iat) * 1000 - Constants.ACCESS_TOKEN_RENEWAL_TIME_RESERVE
    timeout = setSafeTimeout(AccessTokenRenewalService.renewAccessToken, nextRequestTimeout)
  }

  static async renewAccessToken() {
    await LoginServiceActionCreators.getAccessToken(getCurrentCompanyNameMethod(), getCurrentSiteNameMethod())
  }
}

export default AccessTokenRenewalService
