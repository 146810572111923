import React from 'react'
import _ from 'lodash'
import { Alert, Modal } from 'react-bootstrap'
import CompaniesTable from './CompaniesTable'
import CompaniesPanelStore from '../stores/CompaniesPanelStore'
import CompaniesPanelActions from '../actions/CompaniesPanelActionCreators'
import CompanyManagementForm from './CompanyManagementForm'
import NewCompanyForm from './NewCompanyForm'
import RolesPanelStore from '../../users_panel/stores/RolesPanelStore'
import Throbber from '../../Throbber'

class CompaniesPanel extends React.Component {
  static displayName = 'CompaniesPanel'

  state = {
    dataLoaded: false,
    showSuccessMessage: false,
    isDuplicateError: false,
    companies: [],
    customerTypes: [],
    roles: [],
    selectedCompany: {}
  }

  componentDidMount() {
    CompaniesPanelStore.addChangeListener(this.onChange)
    RolesPanelStore.addChangeListener(this.onChangeRolesPanelStore)
  }

  componentWillUnmount() {
    CompaniesPanelStore.removeChangeListener(this.onChange)
    RolesPanelStore.removeChangeListener(this.onChangeRolesPanelStore)
  }

  onPanelShow = () => {
    if (!this.state.dataLoaded) {
      CompaniesPanelActions.fetchCustomerTypes()
      CompaniesPanelActions.fetchCompaniesInfo()
    }
  }

  onCompanyWindowHide = () => {
    this.setState({ selectedCompany: {} })
  }

  onChange = () => {
    var { companies, customerTypes, dataLoaded, isDuplicateError, isCreated, loading } = CompaniesPanelStore.getData()
    if (companies.filter(c => c.id == this.state.selectedCompany.id).length == 0) {
      this.setState({ selectedCompany: {} })
    }

    this.setState({ customerTypes, companies, dataLoaded, isDuplicateError, isCreated, loading })
  }

  onChangeRolesPanelStore = () => {
    var { roles } = RolesPanelStore.getData()
    this.setState({ roles })
  }

  onCompanySelect = companyData => {
    var company = _.find(this.state.companies, company => company.id == companyData.id)
    this.setState({ selectedCompany: company })
  }

  onCompanyUnSelect = () => {
    this.setState({ selectedCompany: {} })
  }

  render() {
    if (this.state.isDuplicateError) {
      var InvalidAlert = <Alert bsStyle='danger'>Company already exists</Alert>
    }

    if (this.state.isCreated) {
      var successAlert = <Alert bsStyle='info'>Created!</Alert>
    }

    return (
      <Throbber show={!this.state.dataLoaded}>
        <CompaniesTable
          isAdmin={this.props.userInfo.isAdmin}
          companies={this.state.companies}
          onCompanySelect={this.onCompanySelect}
        />
        {InvalidAlert}
        {successAlert}
        <NewCompanyForm
          customerTypes={this.state.customerTypes}
          roles={this.state.roles}
          userInfo={this.props.userInfo}
          loading={this.state.loading}
        />
        <Modal
          show={!_.isEmpty(this.state.selectedCompany)}
          onHide={this.onCompanyWindowHide}
          dialogClassName='company-management-panel-window'
          container={this}
        >
          <Modal.Header>
            <h3>{this.state.selectedCompany.name}</h3>
          </Modal.Header>
          <Modal.Body>
            {' '}
            <CompanyManagementForm
              company={this.state.selectedCompany}
              customerTypes={this.state.customerTypes}
              onCompanyUnSelect={this.onCompanyUnSelect}
            />
          </Modal.Body>
        </Modal>
      </Throbber>
    )
  }
}

export default CompaniesPanel
