import BaseStore from '../../stores/BaseStore'

import Constants from '../../Constants'
import Dispatcher from '../../Dispatcher'

export interface IRenaming {
  customerId: CustomerId
  customerName: string
  siteId?: SiteId
  siteName?: string
  log: IRenamingLog[]
}

export interface IRenamingLog {
  renamedAt: string
  oldCustomerName?: string
  newCustomerName?: string
  oldSiteName?: string
  newSiteName?: string
  author: string
}

let renamings: IRenaming[] = []
let pending = false

class RenamingsPanelStore extends BaseStore {
  getData() {
    return { renamings, pending }
  }

  dispatcherIndex = Dispatcher.register(payload => {
    const action = payload.action
    switch (action.type) {
      case Constants.ActionTypes.RENAMINGS_FETCHING:
        pending = true
        this.emitChange()
        break
      case Constants.ActionTypes.RENAMINGS_FETCHED:
        pending = false
        renamings = action.renamings
        this.emitChange()
        break
    }
  })
}

export default new RenamingsPanelStore()
