import React from 'react'
import _ from 'lodash'

class PermissionTableRow extends React.Component {
  static displayName = 'PermissionTableRow'

  state = {
    cloneRoles: _.cloneDeep(this.props.roles)
  }

  roleHasPermission = (role, permission) => {
    return role.permissions.some(p => p.id === permission.id)
  }

  render() {
    const { permission } = this.props
    const { cloneRoles } = this.state
    return (
      <tr>
        <td>
          {permission.id} <span className='small pull-right text-info'>{this.props.permission.level}</span>
        </td>
        {cloneRoles.map(r => (
          <td style={{ cursor: 'default' }} key={r.id}>
            {this.roleHasPermission(r, permission) ? '✅' : '✖️'}
          </td>
        ))}
      </tr>
    )
  }
}

export default PermissionTableRow
