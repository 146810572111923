import BaseStore from '../../../stores/BaseStore'
import Constants from '../../../Constants'
import Dispatcher from '../../../Dispatcher'
import { rolesSortingFn } from '../../../libs/roles'

var roles = []
var permissions = []

class PermissionsPanelStore extends BaseStore {
  getData() {
    return { roles, permissions }
  }

  sortRoles() {
    roles.sort(rolesSortingFn)
  }
  dispatcherIndex = Dispatcher.register(payload => {
    var action = payload.action
    switch (action.type) {
      case Constants.ActionTypes.FETCH_ROLES_AND_PERMISSIONS:
        roles = action.roles
        permissions = action.permissions
        this.sortRoles()
        this.emitChange()
        break
    }
  })
}

export default new PermissionsPanelStore()
