import { Dispatcher } from 'flux'
import Constants from './Constants'
import Logger from 'logger'

const AppDispatcher = Object.assign(new Dispatcher(), {
  handleServerAction(action) {
    const payload = {
      source: Constants.ActionSources.SERVER_ACTION,
      action
    }
    Logger.captureBreadcrumb(`Action ${action.type} dispatched`)
    this._isDispatching ? setTimeout(() => this.dispatch(payload)) : this.dispatch(payload)
  },

  handleViewAction(action) {
    const payload = {
      source: Constants.ActionSources.VIEW_ACTION,
      action
    }
    Logger.captureBreadcrumb(`Action ${action.type} dispatched`)
    this._isDispatching ? setTimeout(() => this.dispatch(payload)) : this.dispatch(payload)
  }
})

export default AppDispatcher
