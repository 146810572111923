import { ILogger } from './Logger'

export default class ConsoleLogger implements ILogger {
  static create() {
    return new ConsoleLogger()
  }

  constructor() {
    window.addEventListener('error', e => this.exception(e.error))
    window.addEventListener('unhandledrejection', e => this.exception(e.reason))
  }

  log(message: string, payload?: any) {
    console.log(message, { extra: payload, level: 'info' })
  }

  info(message: string, payload?: object) {
    console.log(message, { extra: payload, level: 'info' })
  }

  warn(message: string, payload?: any) {
    console.warn(message, { extra: payload, level: 'warn' })
  }

  error(message: string, payload?: any) {
    console.error(message, { extra: payload, level: 'error' })
  }

  exception(exception: Error, payload: any = {}) {
    console.error(exception, { extra: payload })
  }

  failedRequest(message: string, response: any, payload: any) {
    this.error(message, { response, ...payload })
  }

  setUserContext({ email }: User) {
    console.log(`User context: ${email}`)
  }

  setExtraContext(contextData: any) {
    console.log(`Extra context: ${JSON.stringify(contextData)}`)
  }

  captureBreadcrumb() {
    // Not implementable
  }
}
