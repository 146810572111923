import * as React from 'react'
import { classes, style } from 'typestyle'

interface ISitesRolesTableProps {
  sites: Site[]
  sitesRoles: SiteRoles[]
  roles: Role[]
  sitePolicies: { roles: RoleId[] }
  renderSiteRolesRow: (props: { roles: Role[]; site: Site; siteRoles?: SiteRoles }) => JSX.Element
  onSiteUserPolicyChange: (policyAssigned: boolean, roleName: string) => void
  starRoles: RoleId[]
}

const SitesRolesTable = ({
  sitesRoles,
  roles,
  sites,
  renderSiteRolesRow,
  sitePolicies,
  onSiteUserPolicyChange,
  starRoles
}: ISitesRolesTableProps) => {
  return (
    <>
      {starRoles.length > 0 && <h5>User has access to all sites of the customer.</h5>}
      <table className={classes('table table-striped', tableStyle)}>
        <thead>
          <tr style={{ background: '#CCE6FF' }}>
            <th />
            {roles.map(role => {
              const hasSitePolicy = sitePolicies.roles.includes(role.id)
              return (
                <th key={role.id}>
                  <label className={roleCellLabelStyle}>
                    {
                      <input
                        type='checkbox'
                        className={roleCellCheckboxStyle}
                        checked={hasSitePolicy}
                        disabled={starRoles.includes(role.id)}
                        onChange={e => handleSiteUserPolicyChange(e.target.checked, role.id, onSiteUserPolicyChange)}
                      />
                    }
                    <span className={roleTitleStyle}>{role.id}</span>
                  </label>
                </th>
              )
            })}
          </tr>
        </thead>

        <tbody>
          {sites.map(site => {
            const siteRoles = sitesRoles.find(siteRole => siteRole.site === site.id)
            const starRoles = sitesRoles.find(siteRole => siteRole.site === '*')
            let allSiteRoles: string[] = []
            if (starRoles) {
              allSiteRoles = starRoles.roles.concat()
            }
            if (siteRoles) {
              allSiteRoles = [...allSiteRoles, ...siteRoles.roles]
            }
            return renderSiteRolesRow({
              roles,
              siteRoles: { site: site.id, roles: Array.from(new Set(allSiteRoles)) },
              site
            })
          })}
        </tbody>
      </table>
    </>
  )
}

function handleSiteUserPolicyChange(
  checked: boolean,
  roleId: RoleId,
  onSiteUSerPolicyChange: ISitesRolesTableProps['onSiteUserPolicyChange']
) {
  const message = checked
    ? `Assign ${roleId} role to current and future sites?`
    : `Revoke ${roleId} role from current and future sites?`
  if (confirm(message)) {
    onSiteUSerPolicyChange(checked, roleId)
  }
}

export const roleCellLabelStyle = style({
  display: 'flex',
  alignItems: 'center'
})

export const roleCellCheckboxStyle = style({
  margin: '0 4px 0 0 !important'
})

export const roleTitleStyle = style({
  wordBreak: 'break-all',
  display: 'block'
})

export const tableStyle = style({
  $nest: {
    '& tbody': {
      display: 'block',
      overflow: 'auto',
      // @ts-ignore non-standard overflow value but works in chrome
      overflowY: 'overlay',
      maxHeight: '300px'
    },
    '& thead, & tbody tr': {
      width: '100%',
      display: 'table',
      tableLayout: 'fixed'
    },
    '& thead': {
      fontSize: 12
    },
    '& > thead > tr > th': {
      verticalAlign: 'top'
    }
  }
})
export default SitesRolesTable
