import React from 'react'
import _ from 'lodash'
import RolesPanelActionCreators from '../actions/RolesPanelActionCreators'
import SitesRolesTable from './roles_table/SitesRolesTable'
import UserSiteRolesRow from './UserSiteRolesRow'
import { style } from 'typestyle'
import RolesPanelStore from '../stores/RolesPanelStore'
import Throbber from '../../Throbber'
import {
  getRolesOnStarCustomerSite,
  hasStarOnCustomer,
  hasStarOnCustomers,
  hasStarOnCustomersAndSites
} from '../../../libs/roles'

class UserRolesTableRow extends React.Component {
  static displayName = 'UserRolesTableRow'

  static defaultProps = {
    onRemoveUserSites: () => {},
    onUpdateRole: () => {},
    collapsed: true
  }

  state = {
    changed: false,
    fetchingSites: false,
    selectedRole: this.props.userRole.roles[0]
  }

  onRemoveClick = async () => {
    this.setState({ fetchingSites: true })
    await Promise.all(this.props.roles.map(role => this.revokeSiteUserPolicy(role.id)))
    this.removeSitesRoles()
    this.setState({ fetchingSites: false, changed: false, selectedRole: null })
  }

  removeSitesRoles() {
    const { userRole } = this.props
    const sitesRoles = []
    if (!_.isEmpty(userRole?.sites)) {
      userRole.sites.forEach(siteRoles => {
        siteRoles.roles.forEach(role => {
          sitesRoles.push({ site: siteRoles.site, role })
        })
      })
      RolesPanelActionCreators.deleteSitesUserRoles({
        email: this.props.user.email,
        customerId: userRole.customer,
        sitesRoles
      })
    } else {
      RolesPanelActionCreators.clearEmptyCustomerRoles({
        email: this.props.user.email,
        customer: userRole.customer
      })
    }
  }

  getCurrentCompany = () => {
    return _.find(this.props.companies, c => c.id === this.props.customerId)
  }
  getCompanySites = () => {
    const currentCompany = this.getCurrentCompany()
    return currentCompany.constructionSites || []
  }

  getSitesRoles = () => {
    const { user, userRole, customerId } = this.props
    const sitesRole = userRole.sites.slice()
    if (hasStarOnCustomersAndSites(user) && !hasStarOnCustomer(user, customerId)) {
      const starRoles = getRolesOnStarCustomerSite(user)
      sitesRole.push({ site: '*', roles: starRoles })
    }
    return sitesRole
  }

  handleToggleSitesClick = async e => {
    e.preventDefault()
    this.props.onToggle(this.props.customerId)
    if (!this.getCompanySites().length) {
      this.setState({ fetchingSites: true })
      await RolesPanelActionCreators.fetchCustomerSites(this.props.customerId)
      this.setState({ fetchingSites: false })
    }
  }

  handleSiteUserPolicyChanged = async (policyAssigned, roleName) => {
    this.setState({ fetchingSites: true })
    if (policyAssigned) {
      await this.assignSiteUserPolicy(roleName)
    } else {
      await this.revokeSiteUserPolicy(roleName)
    }
    this.setState({ fetchingSites: false })
  }

  async assignSiteUserPolicy(roleName) {
    const { customerId, user, userRole } = this.props
    const sites = this.getCompanySites()

    // assign role to all current sites without this role
    const sitesWithoutRole = sites.filter(site => {
      const { sites } = userRole
      const roleSite = sites.find(s => s.site === site.id)
      if (!roleSite) {
        return true
      }

      return !roleSite.roles.includes(roleName)
    })

    await Promise.all(
      sitesWithoutRole.map(site =>
        RolesPanelActionCreators.createSiteUserRole({ email: user.email, customerId, siteId: site.id, roleName })
      )
    )
    await RolesPanelActionCreators.assignSiteUserRolePolicy(user.email, customerId, roleName)
  }

  async revokeSiteUserPolicy(roleName) {
    const { customerId, user, userRole } = this.props
    const sites = this.getCompanySites()

    // revoke role from all current sites with this role
    const sitesWithoutRole = sites.filter(site => {
      const { sites } = userRole
      const roleSite = sites.find(s => s.site === site.id)
      if (!roleSite) {
        return false
      }

      return roleSite.roles.includes(roleName)
    })

    await Promise.all(
      sitesWithoutRole.map(site =>
        RolesPanelActionCreators.deleteSiteUserRole({ email: user.email, customerId, siteId: site.id, roleName })
      )
    )
    await RolesPanelActionCreators.revokeSiteUserRolePolicy(user.email, customerId, roleName)
  }

  render() {
    const { customerId, companies, collapsed, user } = this.props
    const customer = companies.find(c => c.id === customerId)
    const starOnCustomer = hasStarOnCustomers(user) || hasStarOnCustomer(user, customerId)

    const sitesRoles = this.getSitesRoles()

    const customerRoleSelect = (
      <tr>
        <td className='remove-button-cell'>
          {!starOnCustomer && (
            <a href='#' onClick={this.onRemoveClick}>
              ❌
            </a>
          )}
        </td>
        <td className='company-name-cell'>
          <a
            href='#'
            onClick={this.handleToggleSitesClick}
            className={style({
              textDecoration: 'none',
              borderBottom: '1px dashed',
              cursor: 'pointer'
            })}
          >
            {customer.name}
          </a>
        </td>
      </tr>
    )

    const siteRolesTable = (
      <tr>
        <td colSpan={5} className={style({ paddingRight: '12px !important' })}>
          <Throbber show={this.state.fetchingSites}>
            <SitesRolesTable
              sites={this.getCompanySites()}
              sitesRoles={sitesRoles}
              roles={this.props.roles}
              sitePolicies={RolesPanelStore.getPolicies(this.props.user.email, this.props.customerId)}
              onSiteUserPolicyChange={this.handleSiteUserPolicyChanged}
              starRoles={getRolesOnStarCustomerSite(user)}
              renderSiteRolesRow={({ roles, site, siteRoles }) => {
                return (
                  <UserSiteRolesRow
                    roles={roles}
                    customerId={customerId}
                    email={this.props.user.email}
                    site={site}
                    siteRoles={siteRoles}
                    starRoles={getRolesOnStarCustomerSite(user)}
                  />
                )
              }}
            />
          </Throbber>
        </td>
      </tr>
    )

    return (
      <React.Fragment>
        {customerRoleSelect}
        {!collapsed && siteRolesTable}
      </React.Fragment>
    )
  }
}

export default UserRolesTableRow
