import * as React from 'react'
import { DropdownList } from 'react-widgets'

type CompanySelectionInputProps = {
  companyId?: CustomerId
  onChange: (company?: Customer) => void
  companies: Customer[]
}

const CompanySelectionInput: React.FunctionComponent<CompanySelectionInputProps> = ({
  companies,
  companyId,
  onChange
}) => {
  const company = companies.find(c => c.id === companyId)
  const sortedCompanies = companies.slice().sort((c1: Customer, c2: Customer) => (c1.name > c2.name ? 1 : -1))
  return (
    <DropdownList
      data={sortedCompanies}
      textField={'name'}
      dataKey={'id'}
      filter={'contains'}
      value={company}
      onChange={onChange}
    />
  )
}

export default CompanySelectionInput
