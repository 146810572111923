import React from 'react'
import _ from 'lodash'
import { Modal } from 'react-bootstrap'
import Throbber from '../../Throbber'
import RolesPanelActions from '../actions/RolesPanelActionCreators'
import RolesPanelStore from '../stores/RolesPanelStore'
import UsersTable from './UsersTable'
import UserManagementPanel from './UserManagementPanel'
import UserRegistrationForm from './UserRegistrationForm'
import UserStore from '../../../stores/UserStore'

class UsersPanel extends React.Component {
  static displayName = 'UsersPanel'

  state = {
    dataLoaded: false,
    users: [],
    companies: [],
    roles: [],
    customerTypes: [],
    selectedUser: {},
    errMsg: '',
    fetching: false,
    userUpdating: false
  }

  componentDidMount() {
    RolesPanelStore.addChangeListener(this.onChange)
  }

  componentWillUnmount() {
    RolesPanelStore.removeChangeListener(this.onChange)
  }

  onPanelShow = () => {
    if (!this.state.dataLoaded) {
      RolesPanelActions.fetchRolesInfo()
      RolesPanelActions.getCustomersTypes()
      this.setState({ dataLoaded: true })
    }
  }

  onUserWindowHide = () => {
    RolesPanelActions.clearSelectedUser()
  }

  onChange = () => {
    var {
      users,
      companies,
      roles,
      errMsg,
      isRegistrationSuccess,
      selectedUser,
      customerTypes,
      fetching,
      fetchingUserRoles,
      userCreation,
      userUpdating
    } = RolesPanelStore.getData()

    this.setState({
      users,
      companies,
      roles,
      fetchingUserRoles,
      errMsg,
      fetching,
      selectedUser,
      customerTypes,
      userCreation,
      userUpdating
    })
    if (isRegistrationSuccess) {
      this.onUserRegisterWindowHide()
    }
  }

  onUserSelect = userData => {
    RolesPanelActions.getUserRolesAndSitePolicies(userData.email, userData.company.id)
  }

  handleShowRegisterUserWindowClick = e => {
    e.preventDefault()
    this.setState({ registerUser: true, selectedUser: {} })
  }

  handleMyProfileClick = e => {
    e.preventDefault()
    const { email, company } = UserStore.getData().userInfo
    RolesPanelActions.getUserRolesAndSitePolicies(email, company.id)
  }

  onUserRegisterWindowHide = () => {
    this.setState({ registerUser: false })
    this.setState({ errMsg: '' })
    RolesPanelActions.closeUserCreation()
  }

  onUserCreate = (userData, siteUserPolicyRoles) => {
    const userDataWithCompanyName = { ...userData, company: userData.companyName }
    delete userDataWithCompanyName.companyName
    RolesPanelActions.createUser(userDataWithCompanyName, siteUserPolicyRoles)
  }

  render() {
    return (
      <Throbber show={this.state.fetching}>
        <button className='btn navbar-btn' onClick={this.handleShowRegisterUserWindowClick}>
          Register user
        </button>
        <button className='btn navbar-btn' style={{ marginLeft: 12 }} onClick={this.handleMyProfileClick}>
          My Profile
        </button>
        <Modal
          show={this.state.registerUser}
          onHide={this.onUserRegisterWindowHide}
          dialogClassName='user-register-window'
          container={this}
        >
          <Modal.Header>
            <h3>User registration</h3>
          </Modal.Header>
          <Throbber show={this.state.fetching || this.state.userCreation || this.state.userUpdating}>
            {this.state.companies.length && this.state.roles.length && (
              <UserRegistrationForm
                companies={this.state.companies}
                roles={this.state.roles}
                onUserCreate={this.onUserCreate}
                errMsg={this.state.errMsg}
                onUserRegisterWindowHide={this.onUserRegisterWindowHide}
              />
            )}
          </Throbber>
        </Modal>
        <UsersTable users={this.state.users} onUserSelect={this.onUserSelect} companies={this.state.companies} />
        <Modal
          show={!_.isEmpty(this.state.selectedUser)}
          onHide={this.onUserWindowHide}
          dialogClassName='user-management-panel-window'
          container={this}
        >
          <Throbber show={this.state.fetchingUserRoles}>
            <Modal.Header>
              <h3>{this.state.selectedUser.email}</h3>
            </Modal.Header>

            {!_.isEmpty(this.state.selectedUser) && (
              <UserManagementPanel
                user={this.state.selectedUser}
                roles={this.state.roles}
                companies={this.state.companies}
                customerTypes={this.state.customerTypes}
              />
            )}
          </Throbber>
        </Modal>
      </Throbber>
    )
  }
}

export default UsersPanel
