import React from 'react'
import { style } from 'typestyle'
import PermissionsActions from '../actions/PermissionsPanelActionCreators'
import PermissionsPanelStore from '../stores/PermissionsPanelStore'
import Constants from '../../../Constants'
import PermissionTableRow from './PermissionTableRow'

class PermissionsPanel extends React.Component {
  static displayName = 'PermissionsPanel'

  state = {
    dataLoaded: false,

    roles: [],
    permissions: [],
    levelFilter: Constants.PERMISSIONS_LEVELS.SITE
  }

  componentDidMount() {
    PermissionsPanelStore.addChangeListener(this.onChange)
  }

  componentWillUnmount() {
    PermissionsPanelStore.removeChangeListener(this.onChange)
  }

  handleLevelChanged = e => {
    this.setState({ levelFilter: e.target.value })
  }
  onChange = () => {
    var { permissions, roles } = PermissionsPanelStore.getData()
    this.setState({ permissions, roles })
  }

  onPanelShow = () => {
    if (!this.state.dataLoaded) {
      PermissionsActions.fetchRolesAndPermissions()
      this.setState({ dataLoaded: true })
    }
  }

  render() {
    const { roles, levelFilter, permissions } = this.state
    const filteredPermissions = permissions.filter(permission => permission.level === levelFilter)
    return (
      <div className='col-lg-6 col-md-12'>
        <div className={selectorStyle}>
          <label>
            Level:&nbsp;
            <select value={levelFilter} onChange={this.handleLevelChanged}>
              {Object.keys(Constants.PERMISSIONS_LEVELS).map(level => (
                <option value={level}>{level}</option>
              ))}
            </select>
          </label>
        </div>
        <table className='table table-condensed table-striped roles-table'>
          <tbody>
            <tr style={{ position: 'sticky', top: 0 }}>
              <td />
              {roles.map(r => (
                <td key={r.id}>{r.id}</td>
              ))}
              <td className='save-button-cell' />
            </tr>
            {_.sortBy(filteredPermissions, 'id').map(p => (
              <PermissionTableRow roles={roles} permission={p} key={p.id} />
            ))}
          </tbody>
        </table>
      </div>
    )
  }
}

export default PermissionsPanel

const selectorStyle = style({
  padding: '12px'
})
