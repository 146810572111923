import PERMISSIONS_LEVELS from './permissions_levels'

export const PERMISSIONS = {
    SITE_VIEW: {
        name: 'SITE_VIEW',
        level: PERMISSIONS_LEVELS.SITE
    },
    CUSTOMER_CREATE: {
        name: 'CUSTOMER_CREATE',
        level: PERMISSIONS_LEVELS.SYSTEM
    },

    CSU_API_USER_ROLE_ASSIGN_SITE: {
        name: 'CSU_API_USER_ROLE_ASSIGN_SITE',
        level: PERMISSIONS_LEVELS.SITE
    },

    CSU_API_USER_ROLE_REVOKE_SITE: {
        name: 'CSU_API_USER_ROLE_REVOKE_SITE',
        level: PERMISSIONS_LEVELS.SITE
    }
}
