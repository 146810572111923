import Dispatcher from '../../../Dispatcher'
import $ from '../../../WebUtils'
import Constants from '../../../Constants'
import RolesPanelActions from '../../users_panel/actions/RolesPanelActionCreators'

export default {
  fetchCompaniesInfo() {
    $.getCustomers((err, response) => {
      Dispatcher.handleServerAction({
        type: Constants.ActionTypes.companiesPanelActions.FETCH_COMPANIES_WITH_COUNT_USERS,
        companies: response.body.customers
      })
    })
  },
  createCompany(companyData) {
    Dispatcher.handleServerAction({
      type: Constants.ActionTypes.companiesPanelActions.COMPANY_CREATION_STARTED
    })

    $.createCompany(companyData, err => {
      if (err) {
        return Dispatcher.handleServerAction({
          type: Constants.ActionTypes.companiesPanelActions.COMPANY_CREATED,
          isError: Boolean(err)
        })
      }

      this.fetchCompaniesInfo()
    })
  },
  fetchCustomerTypes() {
    $.getCustomersTypes().then(
      res => {
        Dispatcher.handleServerAction({
          type: Constants.ActionTypes.companiesPanelActions.CUSTOMER_TYPES_FETCHED,
          customerTypes: res.body.customerTypes
        })
      },
      err => alert(err.message)
    )
  },
  updateCompany(companyId, companyData) {
    Dispatcher.handleViewAction({ type: Constants.ActionTypes.companiesPanelActions.COMPANY_UPDATING })
    $.updateCompany(companyId, companyData, (err, response) => {
      this.fetchCompaniesInfo()
      RolesPanelActions.fetchRolesInfo()

      Dispatcher.handleServerAction({
        type: Constants.ActionTypes.companiesPanelActions.COMPANY_UPDATED,
        company: response.body.customer,
        isError: Boolean(response.body.error)
      })
    })
  }
}
