import React from 'react'
import RolesPanelActions from '../actions/RolesPanelActionCreators'
import CompanySelectionInput from '../../CompanySelectionInput'

class NewUserRoleForm extends React.Component {
  static displayName = 'NewUserRoleForm'

  state = {
    customerId: undefined
  }

  static defaultProps = {
    companies: [],
    user: {},
    roles: []
  }

  onAddCustomerClick = e => {
    e.preventDefault()
    if (this.isValid()) {
      RolesPanelActions.addCustomerAccess({
        email: this.props.user.email,
        customerId: this.state.customerId
      })
    }
  }

  handleAccountHolderChanged = ({ id: customerId }) => {
    this.setState({ customerId })
  }

  isValid = () => {
    return this.state.customerId
  }

  render() {
    return (
      <form>
        <div className='form-group'>
          <CompanySelectionInput
            onChange={this.handleAccountHolderChanged}
            companyId={this.state.customerId}
            companies={this.props.companies}
          />
        </div>
        {
          <button type='submit' className='btn btn-default' onClick={this.onAddCustomerClick}>
            Grant Sites Access
          </button>
        }
      </form>
    )
  }
}

export default NewUserRoleForm
