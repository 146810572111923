import BaseStore from '../../../stores/BaseStore'
import Constants from '../../../Constants'
import Dispatcher from '../../../Dispatcher'
import _ from 'lodash'
import { rolesSortingFn } from '../../../libs/roles'

let users = []
let companies = []
let roles = []
let fetching = false
let fetchingUserRoles = false
let selectedUser = {}
let userCreation = false
let customerTypesFetching = false
let userUpdating = false
let newlyCreatedCustomerRole = null
let errMsg = ''
let isRegistrationSuccess = false
let customerTypes = []

class RolesPanelStore extends BaseStore {
  getData() {
    return {
      users,
      companies,
      roles,
      newlyCreatedCustomerRole,
      userCreation,
      fetching: fetching || customerTypesFetching,
      fetchingUserRoles,
      selectedUser,
      userUpdating,
      errMsg,
      customerTypes,
      isRegistrationSuccess
    }
  }

  assignRolesToUser(email, roles) {
    const user = users.find(u => u.email === email)
    user.roles = roles
  }

  assignPoliciesToUser(email, policies) {
    const user = users.find(u => u.email === email)
    user.policies = policies
  }

  getPolicies(email, customerId) {
    const user = users.find(u => u.email === email)
    if (!user.policies || !user.policies[customerId]) {
      return { roles: [] }
    }

    return user.policies[customerId]
  }

  insertNewCustomerRole(email, role) {
    const user = users.find(u => u.email === email)
    const currentRole = user.roles.customers.find(c => c.customer === role.customer)
    if (!currentRole) {
      user.roles.customers.push(role)
      return
    }

    currentRole.roles = role.roles
  }

  updateUser(updatedUser) {
    const user = _.find(users, u => u.id === updatedUser.id)
    _.assign(user, updatedUser)
  }

  createUser(createdUser) {
    users.push(createdUser)
  }

  updateSiteUserRole(email, customerRole) {
    const user = users.find(u => u.email === email)
    const customerRoleObj = user.roles.customers.find(role => role.customer === customerRole.customer)
    if (!customerRoleObj) {
      return
    }
    const siteRole = customerRoleObj.sites.find(siteRole => siteRole.site === customerRole.sites[0].site)
    if (!siteRole) {
      customerRoleObj.sites.push(customerRole.sites[0])
    } else {
      siteRole.roles = customerRole.sites[0].roles
    }
    this.removeEmptyRoles(user)
  }

  removeEmptyRoles(user) {
    const emptyRoles = user.roles.customers.filter(customerRoles => {
      return customerRoles.roles.length === 0 && customerRoles.sites.every(siteRole => siteRole.roles.length === 0)
    })
    emptyRoles.forEach(emptyRole => _.remove(user.roles.customers, role => role.customer === emptyRole.customer))
  }

  sortRoles() {
    roles.sort(rolesSortingFn)
  }

  assignAllSitesStarRole(email, role) {
    const updatedUser = _.find(users, u => u.email === email)
    if (!updatedUser) {
      return
    }
    if (role.customers[0].roles.length === 0 && role.customers[0].sites.every(s => s.roles.length === 0)) {
      // no star roles
      updatedUser.roles.customers = [...updatedUser.roles.customers.filter(r => r.customer !== '*')]
      return
    }
    updatedUser.roles.customers = [...updatedUser.roles.customers.filter(r => r.customer !== '*'), role.customers[0]]
  }

  dispatcherIndex = Dispatcher.register(payload => {
    const action = payload.action
    switch (action.type) {
      case Constants.ActionTypes.FETCHING_USERS:
        fetching = true
        this.emitChange()
        break
      case Constants.ActionTypes.FETCHING_CUSTOMER_TYPES:
        customerTypesFetching = true
        this.emitChange()
        break
      case Constants.ActionTypes.FETCHED_CUSTOMER_TYPES:
        customerTypes = action.customerTypes
        customerTypesFetching = false
        this.emitChange()
        break
      case Constants.ActionTypes.FETCHED_CUSTOMER_SITES:
        const { customerId, sites } = action
        const customer = companies.find(c => c.id === customerId)
        customer.constructionSites = sites
        this.emitChange()
        break
      case Constants.ActionTypes.FETCHED_USERS:
        companies = action.companies
        roles = action.roles
        users = action.users
        fetching = false
        this.sortRoles()
        this.emitChange()
        break
      case Constants.ActionTypes.FETCHING_USER_ROLES:
        fetchingUserRoles = true
        newlyCreatedCustomerRole = null
        this.emitChange()
        break
      case Constants.ActionTypes.USERS_PANEL_SELECTED_USER_CLEAR:
        selectedUser = {}
        this.emitChange()
        break
      case Constants.ActionTypes.FETCHED_USER_ROLES:
        fetchingUserRoles = false
        this.assignRolesToUser(action.email, action.roles)
        this.assignPoliciesToUser(action.email, action.policies)
        selectedUser = users.find(u => u.email === action.email)
        this.emitChange()
        break
      case Constants.ActionTypes.CUSTOMER_ADD_ACCESS:
      case Constants.ActionTypes.SITE_USER_ROLE_CREATION:
        userUpdating = true
        this.emitChange()
        break
      case Constants.ActionTypes.CUSTOMER_USER_ROLE_CREATED: {
        userUpdating = false
        const { email, role } = action
        this.insertNewCustomerRole(email, role)
        newlyCreatedCustomerRole = role
        this.emitChange()
        break
      }
      case Constants.ActionTypes.SITE_USER_ROLE_CREATED:
      case Constants.ActionTypes.SITE_USER_ROLE_DELETED:
        const { email, role } = action
        this.updateSiteUserRole(email, role)
        userUpdating = false
        this.emitChange()
        break
      case Constants.ActionTypes.SITE_USER_ROLE_POLICIES_UPDATED: {
        const { email, policies } = action
        this.assignPoliciesToUser(email, policies)
        this.emitChange()
        break
      }
      case Constants.ActionTypes.CUSTOMER_EMPTY_REMOVED: {
        const { email } = action
        const user = users.find(u => u.email === email)
        this.removeEmptyRoles(user)
        userUpdating = false
        this.emitChange()
        break
      }
      case Constants.ActionTypes.USER_CREATION_STARTED:
        userCreation = true
        this.emitChange()
        break
      case Constants.ActionTypes.USER_CREATED: {
        errMsg = ''
        userCreation = false
        isRegistrationSuccess = true
        const { user, roles } = action
        this.createUser(user)
        this.assignRolesToUser(user.email, roles)
        this.emitChange()
        break
      }
      case Constants.ActionTypes.ALL_SITES_STAR_ROLE_UPDATED: {
        const { email, roles } = action
        this.assignAllSitesStarRole(email, roles)
        this.emitChange()
        break
      }
      case Constants.ActionTypes.USER_CREATE_FAILED:
        errMsg = action.error
        userCreation = false
        isRegistrationSuccess = false
        this.emitChange()
        break
      case Constants.ActionTypes.USER_CREATION_CLOSED:
        errMsg = ''
        userCreation = false
        isRegistrationSuccess = false
        this.emitChange()
        break
      case Constants.ActionTypes.USER_UPDATING:
        userUpdating = true
        this.emitChange()
        break
      case Constants.ActionTypes.USER_UPDATED:
        const updatedUser = action.user
        userUpdating = false
        this.updateUser(updatedUser)
        this.emitChange()
        break
      case Constants.ActionTypes.USER_DELETED:
        const deletedUserEmail = action.email
        selectedUser = {}
        _.remove(users, u => u.email === deletedUserEmail)
        this.emitChange()
        break
      case Constants.ActionTypes.USER_PASSWORD_UPDATED:
        this.emit(Constants.PASSWORD_UPDATE_EVENT)
        break
    }
  })
}

export default new RolesPanelStore()
