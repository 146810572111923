import { AnalyticsUtilsAbstract, UserInfo, IUacBackendInfo } from '@traceair/browser-tracking-lib'
import IClientTrackingInterface = AppConfig.IClientTrackingInterface
import UserStore from '../../stores/UserStore'
import Constants from '../../Constants'
import AccessTokenStore from '../../stores/AccessTokenStore'

// @ts-ignore comes from local.js
const config: IClientTrackingInterface = CLIENT_TRACKING_CONFIG

// @ts-ignore comes from local.js
const appVersion: string = VERSION

function strDateToTimestamp(date?: string): number | undefined {
  return date ? new Date(date).getTime() : undefined
}

class AnalyticsUtils extends AnalyticsUtilsAbstract {
  private userInfo?: UserInfo

  constructor() {
    super()

    this.setSessionId()
    UserStore.addListener(Constants.LOGIN_SUCCESS_EVENT, this.updateUserInfo)
    UserStore.addListener(Constants.CHANGE_EVENT, this.updateUserInfo)
  }

  public updateUserInfo = async () => {
    const currentUser = AccessTokenStore.getData().user

    if (currentUser) {
      this.userInfo = {
        userId: currentUser.id || '',
        email: currentUser.email,
        name: currentUser.name,
        title: currentUser.title,
        company: currentUser.company ? currentUser.company.name : '',
        companyId: currentUser.company ? currentUser.company.id : '',
        companyType: currentUser.company ? currentUser.company.type : '',
        titleDescription: currentUser.titleDescription || '',
        customers: [],
        phone: currentUser.phone || '',
        lang: currentUser.lang,
        lastname: currentUser.lastname,
        createdTime: strDateToTimestamp(currentUser.createdAt),
        updatedTime: strDateToTimestamp(currentUser.updatedAt),
        location: config.mixpanel.location
      }

      if (this.isEnabled()) {
        this.setUserIdCookie(currentUser.id)
        this.setLang(currentUser.lang)
      }
    }
  }

  public trackAction(eventMsg: string, params: Record<string, unknown> = {}) {
    this.track(`(Adminka) ${eventMsg}`, params)
  }

  public getUserInfo(): UserInfo | undefined {
    return this.userInfo
  }

  public getAppParams() {
    return {
      componentVersion: appVersion,
      component: 'Adminka',
      componentData: {},
      // subsystem 'BO' set specifically, it is planned to collect TA users actions
      // such statistic could spoil CSU stat.
      // this is a temporary solution
      subsystem: 'BO' as const,
      env: config.mixpanel.environment
    }
  }

  async getBackendInfo(): Promise<IUacBackendInfo> {
    return {
      url: `${config.mixpanel.backendUrl}/api/store-action`
    }
  }

  isEnabled() {
    return config.mixpanel.enabled
  }
}

const analytics = new AnalyticsUtils()

function trackAction(eventMsg = 'incorrect event', params: Record<string, unknown> = {}) {
  analytics.trackAction(eventMsg, params)
}

export default trackAction
