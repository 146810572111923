import * as React from 'react'
import RenamingsPanelStore, { IRenaming } from '../RenamingsPanelStore'
import { fetchRenamings } from '../RenamingActions'
import RenamingCard from './RenamingCard'
import Throbber from '../../Throbber'

interface IRenamingsPanelState {
  renamings: IRenaming[]
  pending: boolean
  filter: string
}

export default class RenamingsPanel extends React.Component<{}, IRenamingsPanelState> {
  constructor(props: {}) {
    super(props)
    const { renamings, pending } = RenamingsPanelStore.getData()
    this.state = {
      renamings,
      pending,
      filter: ''
    }
  }

  componentDidMount() {
    RenamingsPanelStore.addChangeListener(this.handleRenamingsStoreChanged)
  }

  componentWillUnmount() {
    RenamingsPanelStore.removeChangeListener(this.handleRenamingsStoreChanged)
  }

  handleRenamingsStoreChanged = () => {
    const { renamings, pending } = RenamingsPanelStore.getData()
    this.setState({ renamings, pending })
  }

  onPanelShow = () => {
    fetchRenamings(this.state.filter)
  }

  handleFilterChanged = e => {
    this.setState({ filter: e.target.value }, () => {
      fetchRenamings(this.state.filter)
    })
  }

  render() {
    const { renamings, pending, filter } = this.state
    return (
      <div className={'renamings-panel'}>
        <input
          className='form-control'
          placeholder={'Find renamed customer or site...'}
          onChange={this.handleFilterChanged}
          style={{ margin: '12px 0' }}
        />

        <Throbber show={pending}>
          {!renamings.length && <h3>No renamings found</h3>}
          {renamings.map(r => (
            <RenamingCard key={r.customerId + r.siteId} renaming={r} filter={filter} />
          ))}
        </Throbber>
      </div>
    )
  }
}
