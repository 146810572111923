import AppDispatcher from '../Dispatcher'
import Constants from '../Constants'
import $ from '../WebUtils'
import LoginServiceActionCreators from './LoginServiceActionCreators'
import { decodeJWT } from '../stores/AccessTokenStore'
import logger from '../libs/logger/Logger'

export default {
  login(username, password) {
    AppDispatcher.handleViewAction({
      type: Constants.ActionTypes.LOGIN_ATTEMPT
    })

    LoginServiceActionCreators.login(username, password, (err, res) => {
      if (err || res.error) {
        return AppDispatcher.handleViewAction({ type: Constants.ActionTypes.LOGIN_FAILED, error: err || res.error })
      }
    })
  },

  async getLoginStatus() {
    AppDispatcher.handleViewAction({
      type: Constants.ActionTypes.LOGIN_ATTEMPT
    })
    try {
      const [jwt, siteViewJwt] = await LoginServiceActionCreators.fetchTokens()
      AppDispatcher.handleServerAction({
        type: Constants.ActionTypes.ACCESS_TOKEN_RECEIVED,
        jwt,
        siteViewJwt
      })
      const { user } = decodeJWT(jwt).payload
      AppDispatcher.handleViewAction({
        type: Constants.ActionTypes.LOGIN_SUCCESS,
        userInfo: user
      })
      logger.captureBreadcrumb('Success login', { userAgent: navigator.userAgent, email: user.email })
    } catch (err) {
      const { url } = LOGIN_PAGE
      const loginServiceUrl = LOGIN_SERVICE_URL
      location.href = `${url}?referer=${encodeURIComponent(location.href)}&loginServiceBaseUrl=${encodeURIComponent(
        loginServiceUrl
      )}`
    }
  },

  logout() {
    $.logoutFromLoginService(err => {
      if (err) {
        return console.error(err)
      }

      location.reload()
    })
  },

  updateUser(userData) {
    AppDispatcher.handleViewAction({
      type: Constants.ActionTypes.APP_USER_UPDATING
    })

    if (userData.company && userData.company.id) {
      userData.company = userData.company.id
    }
    $.updateSelf(userData, (err, response) => {
      AppDispatcher.handleServerAction({ type: Constants.ActionTypes.APP_USER_UPDATED, user: response.body.user })
    })
  },

  updateUserPass(email, pass) {
    $.updatePassword(email, pass, err => {
      if (err) {
        return console.error(err)
      }
      AppDispatcher.handleServerAction({ type: Constants.ActionTypes.USER_PASSWORD_UPDATED })
    })
  },

  changeUserPass(email, currentPass, newPass, newPassConfirmed) {
    $.changePassword(email, currentPass, newPass, newPassConfirmed, err => {
      if (err) {
        AppDispatcher.handleServerAction({ type: Constants.ActionTypes.USER_PASSWORD_UPDATE_FAILED })
        return console.error(err)
      }

      AppDispatcher.handleServerAction({ type: Constants.ActionTypes.USER_PASSWORD_UPDATED })
    })
  }
}
