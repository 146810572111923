import * as React from 'react'
import Throbber from '../../../Throbber'
import { style } from 'typestyle'

interface ISiteRolesRowProps {
  site: Site
  siteRoles?: SiteRoles
  roles: Role[]
  pending: boolean
  onRoleChanged: (checked: boolean, role: Role) => void
  readonlyRoles: RoleId[]
}

const SiteRolesRow: React.FunctionComponent<ISiteRolesRowProps> = function ({
  siteRoles,
  onRoleChanged,
  roles,
  site,
  pending,
  readonlyRoles
}: ISiteRolesRowProps) {
  return (
    <tr>
      <td>
        {site.name} {getSiteActivityLabel(site)}
      </td>
      {pending && (
        <td colSpan={4}>
          <Throbber show={pending} mini className={style({ minHeight: '1px !important' })} />
        </td>
      )}
      {!pending &&
        roles.map(role => (
          <td key={role.id}>
            <input
              disabled={readonlyRoles.includes(role.id)}
              type='checkbox'
              checked={siteRoles && siteRoles.roles.some(r => r === role.id)}
              onChange={e => onRoleChanged(e.target.checked, role)}
            />
          </td>
        ))}
    </tr>
  )
}

function getSiteActivityLabel(site: Site) {
  if (site.projectActivity === 'ACTIVE') {
    return null
  }
  let labelColor
  let labelText
  if (site.projectActivity === 'ARCHIVED') {
    labelColor = '#B89125'
    labelText = '<archived>'
  } else if (site.projectActivity === 'STARTING') {
    labelColor = '#00A546'
    labelText = '<starting>'
  }
  return (
    <>
      <br />
      <span
        style={{
          color: labelColor,
          fontWeight: 500,
          fontSize: 12
        }}
      >
        {labelText}
      </span>
    </>
  )
}

export default SiteRolesRow
