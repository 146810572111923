import Constants from '../Constants'
import * as events from 'events'

abstract class BaseStore extends events.EventEmitter {
  // Allow Controller-View to register itself with store
  addChangeListener(callback: () => void) {
    this.on(Constants.CHANGE_EVENT, callback)
  }

  removeChangeListener(callback: () => void) {
    this.removeListener(Constants.CHANGE_EVENT, callback)
  }

  // triggers change listener above, firing controller-view callback
  emitChange() {
    this.emit(Constants.CHANGE_EVENT)
  }

  abstract getData()
}

export default BaseStore
