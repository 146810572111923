import * as React from 'react'
import RolesPanelActionCreators from '../actions/RolesPanelActionCreators'
import SiteRolesRow from './roles_table/SiteRolesRow'
interface IUserSiteRolesRowProps {
  customerId: CustomerId
  email: string
  site: Site
  siteRoles?: SiteRoles
  roles: Role[]
  starRoles: RoleId[]
}

interface IUserSiteRolesRowState {
  pending: boolean
}

export default class UserSiteRolesRow extends React.Component<IUserSiteRolesRowProps, IUserSiteRolesRowState> {
  state = {
    pending: false
  }
  handleRoleChanged = async (checked: boolean, role: Role) => {
    const { site, customerId, email } = this.props
    try {
      this.setState({ pending: true })
      if (checked) {
        await RolesPanelActionCreators.createSiteUserRole({
          email: email,
          customerId,
          siteId: site.id,
          roleName: role.id
        })
      } else {
        await RolesPanelActionCreators.deleteSiteUserRole({
          email: email,
          customerId,
          siteId: site.id,
          roleName: role.id
        })
      }
    } catch (err) {
      alert(err.message)
    }
    this.setState({ pending: false })
  }

  render() {
    const { siteRoles, roles, site, starRoles } = this.props
    const { pending } = this.state

    return (
      <SiteRolesRow
        pending={pending}
        siteRoles={siteRoles}
        roles={roles}
        site={site}
        onRoleChanged={this.handleRoleChanged}
        readonlyRoles={starRoles}
      />
    )
  }
}
