import $ from 'superagent'
import AccessTokenStore from './stores/AccessTokenStore'
import logger from 'logger'

const APIServiceHost = API_SERVICE_HOST_URL

const authorizationHeader = 'Authorization'

// Add csrf and access token injection to requests
const _end = $.Request.prototype.end
$.Request.prototype.end = function (cb, jwt) {
  if (!jwt) {
    jwt = AccessTokenStore.getData().jwt
  }

  if (Boolean(jwt) && !this._skipJWT && !this.get(authorizationHeader)) {
    setJWT(this, jwt)
  }

  if (this.method !== 'OPTIONS') {
    this.set('TraceAir-Session-Id', logger.sessionId)
  }

  if (this.method === 'POST' || this.method === 'PUT' || this.method === 'DELETE') {
    if (!this._data && !this._skipData) {
      this.send({})
    }
  }
  _end.apply(this, arguments)
}

function setJWT(req, jwt) {
  req.set(authorizationHeader, `Bearer ${jwt}`)
}

function skipJWT(req) {
  req._skipJWT = true
}

const CSUServiceHost = CSU_SERVICE_CONFIG.host
const CSUServiceVersion = CSU_SERVICE_CONFIG.version

function callback(cb, err, res) {
  cb(err || res.error, res)
}

function logError(req) {
  req.on('response', res => {
    if (res.error) {
      const req = res.req
      logger.failedRequest(
        `Failed request: ${req.method} ${req.url}`,
        {
          url: req.url,
          body: req._data
        },
        res
      )
    }
  })
  req.on('error', err => {
    logger.failedRequest(`Failed request: ${req.method} ${req.url}`, {
      url: req.url,
      err: err.message
    })
  })
}

const CommonWebUtils = {
  loadJSONFile(filepath) {
    return $.get(filepath).use(logError).use(skipJWT)
  },
  loginByService(username, password, cb) {
    return $.post(`${LOGIN_SERVICE_URL}/api/login`)
      .send({
        email: username,
        password
      })
      .withCredentials()
      .set('Accept', 'application/json')
      .use(logError)
      .use(skipJWT)
      .end(cb)
  },
  getAccessToken(companyName, { skipLog = false, permissions, site } = {}, cb) {
    const request = $.post(`${LOGIN_SERVICE_URL}/api/me`)
      .send({ customer: companyName, permissions, site })
      .withCredentials()
    if (!skipLog) {
      request.use(logError)
    }
    return request.use(skipJWT).end(cb)
  },
  logoutFromLoginService(cb) {
    return $.post(`${LOGIN_SERVICE_URL}/api/logout`).withCredentials().use(logError).use(skipJWT).end(cb)
  },
  updateSelf(userData, cb) {
    $.put(`${CSU_SERVICE_CONFIG.host}/api/${CSU_SERVICE_CONFIG.version}/users/self`)
      .send({ user: userData })
      .use(logError)
      .end(cb)
  },
  changePassword(email, currentPass, newPass, newPassConfirmed, cb) {
    $.post(`${LOGIN_SERVICE_URL}/api/users/${email}/change-password`)
      .send({
        currentPass,
        newPass,
        newPassConfirmed
      })
      .use(logError)
      .end(cb)
  },
  getConstructionCompanyInfo(cb) {
    return $.get(`${CSU_SERVICE_CONFIG.host}/api/${CSU_SERVICE_CONFIG.version}/customers`).use(logError).end(cb)
  },
  renameCompany(customerId, customerNewName, jwt) {
    return $.put(`${CSU_SERVICE_CONFIG.host}/api/${CSU_SERVICE_CONFIG.version}/customers/${customerId}/set-name`)
      .send({ name: customerNewName })
      .use(req => setJWT(req, jwt))
      .use(logError)
  },
  searchSitesByName(name, activities) {
    return $.get(`${APIServiceHost}/api/sites/search/${name}`).query({ activities }).use(logError)
  },
  getSites(companyName, cb, jwt) {
    const url = `${APIServiceHost}/api/customers/${companyName}/sites`
    const req = $.get(url)
    return req.use(logError).end(cb, jwt)
  },
  getSite(companyName, siteName, cb, jwt) {
    return $.get(
      `${CSU_SERVICE_CONFIG.host}/api/${CSU_SERVICE_CONFIG.version}/customers/${companyName}/sites/${siteName}`
    )
      .use(logError)
      .end(cb, jwt)
  },
  renameSite(customerId, siteId, siteNewName) {
    const req = $.put(
      `${CSU_SERVICE_CONFIG.host}/api/${CSU_SERVICE_CONFIG.version}/customers/${customerId}/sites/${siteId}/set-name`
    )
      .send({ name: siteNewName })
      .use(logError)
    return req
  }
}

const WebUtils = {
  ...CommonWebUtils,

  getUsers(cb) {
    $.get(`${CSUServiceHost}/api/${CSUServiceVersion}/users/all`).use(logError).end(callback.bind(null, cb))
  },

  getUserRoles(email) {
    return $.get(`${CSUServiceHost}/api/${CSUServiceVersion}/users/${email}/roles`).use(logError)
  },

  updateUser(email, userData, cb) {
    $.put(`${CSUServiceHost}/api/${CSUServiceVersion}/users/${email}`)
      .send({ user: userData })
      .use(logError)
      .end(callback.bind(null, cb))
  },

  registerUser(userData, customerId, siteId, cb, jwt) {
    $.post(`${LOGIN_SERVICE_URL}/api/customers/${customerId}/sites/${siteId}/register`)
      .send({ user: userData, loginServiceBaseUrl: encodeURIComponent(LOGIN_SERVICE_URL_FOR_REG_MAILS) })
      .use(logError)
      .end(cb, jwt)
  },

  updatePassword(email, password, cb) {
    $.post(`${LOGIN_SERVICE_URL}/api/users/${email}/new-password`)
      .send({ password })
      .use(logError)
      .end(callback.bind(null, cb))
  },

  removeUser(email, cb) {
    $.del(`${CSUServiceHost}/api/${CSUServiceVersion}/users/${email}`).use(logError).end(callback.bind(null, cb))
  },

  getCustomers(cb) {
    $.get(`${CSUServiceHost}/api/${CSUServiceVersion}/customers/all?companies=true`)
      .use(logError)
      .end(callback.bind(null, cb))
  },

  createCompany(customer, cb) {
    return $.post(`${CSUServiceHost}/api/${CSUServiceVersion}/customers`)
      .send({ customer })
      .use(logError)
      .end(callback.bind(null, cb))
  },

  getCustomersTypes() {
    return $.get(`${CSUServiceHost}/api/${CSUServiceVersion}/customers-types`).use(logError)
  },

  updateCompany(companyId, companyData, cb) {
    $.put(`${CSUServiceHost}/api/${CSUServiceVersion}/customers/${companyId}`)
      .send({ customer: companyData })
      .use(logError)
      .end(callback.bind(null, cb))
  },

  getRoles(cb) {
    $.get(`${CSUServiceHost}/api/${CSUServiceVersion}/roles`).use(logError).end(callback.bind(null, cb))
  },

  addSiteUserRole({ email, roleName, customerId, siteId }, cb, jwt = null) {
    $.put(`${CSUServiceHost}/api/${CSUServiceVersion}/users/${email}/roles/${roleName}`)
      .send({ customerId, siteId })
      .use(logError)
      .end(callback.bind(null, cb), jwt)
  },

  removeSiteUserRole({ email, roleName, customerId, siteId }, cb, jwt = null) {
    $.del(`${CSUServiceHost}/api/${CSUServiceVersion}/users/${email}/roles/${roleName}`)
      .send({ customerId, siteId })
      .use(logError)
      .end(callback.bind(null, cb), jwt)
  },

  getPermissions(cb) {
    $.get(`${CSUServiceHost}/api/${CSUServiceVersion}/permissions`).use(logError).end(callback.bind(null, cb))
  },

  resendWelcomeMail({ email, customerId, siteId }, cb, jwt = null) {
    $.post(`${LOGIN_SERVICE_URL}/api/customers/${customerId}/sites/${siteId}/send-welcome-mail`)
      .send({
        email,
        customerId,
        siteId,
        loginServiceBaseUrl: encodeURIComponent(LOGIN_SERVICE_URL_FOR_REG_MAILS)
      })
      .use(logError)
      .end(callback.bind(null, cb), jwt)
  },

  listSiteUserRolePolicy(email, customerId, jwt) {
    return $.get(`${CSUServiceHost}/api/${CSUServiceVersion}/customers/${customerId}/policies/users/${email}/sites`)
      .use(req => setJWT(req, jwt))
      .use(logError)
  },
  assignSiteUserRolePolicy(email, customerId, roleName, jwt) {
    return $.put(`${CSUServiceHost}/api/${CSUServiceVersion}/customers/${customerId}/policies/users/${email}/sites`)
      .send({
        customer: customerId,
        role: roleName
      })
      .use(req => setJWT(req, jwt))
      .use(logError)
  },
  revokeSiteUserRolePolicy(email, customerId, roleName, jwt) {
    return $.delete(`${CSUServiceHost}/api/${CSUServiceVersion}/customers/${customerId}/policies/users/${email}/sites`)
      .send({
        customer: customerId,
        role: roleName
      })
      .use(req => setJWT(req, jwt))
      .use(logError)
  },
  assignStarRoleOnAllSites(email, roleName) {
    return $.put(`${CSUServiceHost}/api/${CSUServiceVersion}/users/${email}/roles/${roleName}`)
      .send({
        customerId: '*',
        siteId: '*'
      })
      .use(logError)
  },
  revokeStarRoleOnAllSites(email, roleName) {
    return $.delete(`${CSUServiceHost}/api/${CSUServiceVersion}/users/${email}/roles/${roleName}`)
      .send({
        customerId: '*',
        siteId: '*'
      })
      .use(logError)
  },
  fetchRenamings(filter, limit) {
    return $.get(`${CSUServiceHost}/api/${CSUServiceVersion}/renamings`).query({ filter, limit }).use(logError)
  }
}
export default WebUtils
