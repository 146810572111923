import React from 'react'
import Griddle from 'griddle-react'
import { decodeCustomerTypeName } from '../../../libs/customer-type-decoder'

class CompaniesTable extends React.Component {
  static displayName = 'CompaniesTable'

  static defaultProps = {
    companies: [],
    onCompanyClick: () => {}
  }

  onCompanyClick = row => {
    this.props.isAdmin && this.props.onCompanySelect(row.props.data)
  }

  render() {
    var results = this.props.companies
      .map(companyData => {
        const company = { ...companyData }
        company.createdDate = new Date(company.createdAt)
        company.customerType = decodeCustomerTypeName(company.type) || '-'
        company.isAccountHolder = company.isAccountHolder ? '✔️' : '-'
        return company
      })
      .sort((c1, c2) => c2.createdDate - c1.createdDate)

    return (
      <Griddle
        results={results}
        gridClassName='companies-griddle'
        showFilter={true}
        onRowClick={this.onCompanyClick}
        resultsPerPage={20}
        initialSortAscending={false}
        tableClassName='companies-table'
        columnMetadata={[
          { columnName: 'name', displayName: 'Name' },
          { columnName: 'customerType', displayName: 'Type' },
          { columnName: 'isAccountHolder', displayName: 'Account Holder?' }
        ]}
        columns={['name', 'customerType', 'isAccountHolder']}
      />
    )
  }
}

export default CompaniesTable
