import 'styles/index.css'
import React from 'react'
import ReactDOM from 'react-dom'
import UserStore from '../../stores/UserStore'
import UserActions from '../../actions/UserActionCreators'
import LocaleStore from 'i18n'
import { Tab, Tabs } from 'react-bootstrap'
import AccessTokenRenewalService from '../../libs/AccessTokenRenewalService'
import UsersPanel from '../users_panel/controls/UsersPanel'
import CompaniesPanel from '../companies_panel/controls/CompaniesPanel'
import PermissionsPanel from '../permissions_panel/controls/PermissionsPanel'
import RenamingsPanel from '../renamings_panel/controls/RenamingsPanel'
import trackAction from '../../libs/analytics/ActionTracking'

const officeUrl = OFFICE_URL

class App extends React.Component {
  state = {
    activeTab: 0,
    user: UserStore.getData(),
    messages: LocaleStore.getData().localizationData.messages
  }

  _onChange = () => {
    const wasNotAuth = !this.state.user.isAuth
    this.setState(
      {
        user: UserStore.getData(),
        messages: LocaleStore.getData().localizationData.messages
      },
      () => {
        wasNotAuth && this.state.user.isAuth && this.onTabSelect(1)
      }
    )
  }

  componentDidMount() {
    UserStore.addChangeListener(this._onChange)
    LocaleStore.addChangeListener(this._onChange)
    AccessTokenRenewalService.listenToStoreChanges()
    UserActions.getLoginStatus()

    trackAction('App is opened', { isInitial: true })
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this._onChange)
    LocaleStore.removeChangeListener(this._onChange)
  }

  handleLogoutClick = () => {
    UserActions.logout()
  }

  onTabSelect = key => {
    var activePanel
    switch (key) {
      case 1:
        activePanel = this.refs.usersPanel
        break
      case 2:
        activePanel = this.refs.permissionsPanel
        break
      case 3:
        activePanel = this.refs.companiesPanel
        break
      case 4:
        activePanel = this.refs.renamingsPanel
        break
    }
    if (!activePanel) {
      return
    }
    activePanel.onPanelShow()

    this.setState({ activeTab: key }, () => {
      // fix Tabs bug with no default selected tab
      var tabContent = ReactDOM.findDOMNode(this).querySelector('.tab-content')
      var tabs = Array.prototype.slice.call(tabContent.childNodes)
      tabs.forEach(t => (t.className = 'tab-pane fade'))

      ReactDOM.findDOMNode(activePanel).parentNode.className = 'tab-pane fade active in'
    })
  }

  render() {
    var user = this.state.user
    if (!user.isAuth) {
      return <h1>Authorizing...</h1>
    }
    if (!user.userInfo.isAdmin) {
      return (
        <h1>
          This page is for admins only, sorry.{' '}
          <a href='#' onClick={this.handleLogoutClick}>
            Logout
          </a>
        </h1>
      )
    }

    var usersPanel = <UsersPanel ref='usersPanel' />
    var companiesPanel = <CompaniesPanel userInfo={user.userInfo} ref='companiesPanel' />
    var permissionsPanel = <PermissionsPanel ref='permissionsPanel' />
    var renamingsPanel = <RenamingsPanel ref='renamingsPanel' />
    var adminPanels = (
      <div className='admin-panels'>
        <a href={officeUrl || '/'} className='left' style={{ fontSize: 22, marginTop: 6, marginRight: 12 }}>
          🏠
        </a>
        <Tabs defaultActiveKey={0} activeKey={this.state.activeTab} onSelect={this.onTabSelect}>
          <Tab eventKey={1} title='Users'>
            {usersPanel}
          </Tab>
          <Tab eventKey={2} title='Permissions'>
            {permissionsPanel}
          </Tab>
          <Tab eventKey={3} title='Customers'>
            {companiesPanel}
          </Tab>
          <Tab eventKey={4} title='Renamings'>
            {renamingsPanel}
          </Tab>
        </Tabs>
      </div>
    )

    return <div className='container-fluid main-container'>{adminPanels}</div>
  }
}

export default App
