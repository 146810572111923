import _ from 'lodash'
import React, { Component } from 'react'
import Throbber from '../../Throbber'
import RolesPanelActionCreators from '../actions/RolesPanelActionCreators'

export default class ResendActivationMailForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pending: false,
      success: false,
      error: null
    }
  }

  defaultProps = { user: {} }

  isUserActivated() {
    const { user } = this.props
    return _.isEmpty(user.accessToken)
  }

  hasRolesInCompanies() {
    const { user } = this.props
    return !_.isEmpty(user.roles)
  }

  handleResendWelcomeMailFormSubmit(e) {
    e.preventDefault()
    this.setState({ pending: true })
    const { user } = this.props

    const customersWithSiteAccess = user.roles.customers.filter(c => !!c.sites.filter(s => !!s.roles.length).length)
    const customerId = customersWithSiteAccess[0].customer
    const sitesWithAccess = customersWithSiteAccess[0].sites.filter(s => !!s.roles.length)
    const siteId = sitesWithAccess[0].site

    RolesPanelActionCreators.resendWelcomeMail(user.email, customerId, siteId, err => {
      this.setState({ pending: false })
      if (err) {
        return this.setState({ error: err, success: false })
      }

      this.setState({ error: null, success: true })
    })
  }

  render() {
    const isActivated = this.isUserActivated(),
      hasRolesInCompanies = this.hasRolesInCompanies()

    const { pending, success, error } = this.state

    return (
      <form className='resend-welcome-mail-form' onSubmit={this.handleResendWelcomeMailFormSubmit.bind(this)}>
        <Throbber show={pending} mini={true}>
          {error && this.renderError(error)}
          {!success && this.renderResendButton(isActivated, hasRolesInCompanies)}
          {success && <span>✅</span>}
        </Throbber>
      </form>
    )
  }

  renderResendButton(isActivated, hasRolesInCompanies) {
    return (
      <section>
        <button disabled={isActivated || !hasRolesInCompanies} className='btn btn-default'>
          Resend Welcome Mail
        </button>

        {isActivated && <i className='cannot-resend-welcome-mail-message'>User already activated</i>}

        {!hasRolesInCompanies && (
          <i className='cannot-resend-welcome-mail-message'>User should be attached to company</i>
        )}
      </section>
    )
  }

  renderError(error) {
    return <div className='alert alert-danger'>{error.code || error.message}</div>
  }
}
