import BaseStore from '../../../stores/BaseStore'
import Constants from '../../../Constants'
import Dispatcher from '../../../Dispatcher'
import _ from 'lodash'

var companies = []
let customerTypes = []
var loading = false
var customersLoaded = false
var customerTypesLoaded = false
var isDuplicateError = false
let isCreated = false

class CompaniesPanelStore extends BaseStore {
  getData() {
    return {
      companies,
      loading,
      dataLoaded: customersLoaded && customerTypesLoaded,
      isCreated,
      isDuplicateError,
      customerTypes
    }
  }

  insertNewCompany = createdCompany => {
    companies.push(createdCompany)
  }
  updateCompany = updatedCompany => {
    var company = _.find(companies, c => c.id === updatedCompany.id)
    _.assign(company, updatedCompany)
  }
  updateUsersAmountCompany = (idCompany, addUsersAmount) => {
    var company = _.find(companies, c => c.id === idCompany)
    var updatedCompany = company
    updatedCompany.usersAmount += addUsersAmount
    _.assign(company, updatedCompany)
  }
  dispatcherIndex = Dispatcher.register(payload => {
    var action = payload.action
    switch (action.type) {
      case Constants.ActionTypes.companiesPanelActions.FETCH_COMPANIES_WITH_COUNT_USERS:
        companies = action.companies || []
        customersLoaded = true
        this.emitChange()
        break
      case Constants.ActionTypes.companiesPanelActions.CUSTOMER_TYPES_FETCHED:
        customerTypes = action.customerTypes
        customerTypesLoaded = true
        this.emitChange()
        break
      case Constants.ActionTypes.companiesPanelActions.COMPANY_CREATION_STARTED:
        loading = true
        isCreated = false
        this.emitChange()
        break
      case Constants.ActionTypes.companiesPanelActions.COMPANY_CREATED:
        if (!action.isError) {
          var createdCompany = action.createdCompany
          isCreated = true
          loading = false
          createdCompany.usersAmount = 0
          isDuplicateError = false
          this.insertNewCompany(createdCompany)
        } else {
          isDuplicateError = true
        }
        this.emit(Constants.ActionTypes.companiesPanelActions.COMPANY_CREATED)
        this.emitChange()
        break
      case Constants.ActionTypes.companiesPanelActions.COMPANY_UPDATING:
        loading = true
        this.emitChange()
        break
      case Constants.ActionTypes.companiesPanelActions.COMPANY_SWAPPED:
        action.companies.forEach(this.updateCompany)
        this.emitChange()
        break
      case Constants.ActionTypes.companiesPanelActions.COMPANY_UPDATED:
        if (!action.isError) {
          loading = false
          isDuplicateError = false
        } else {
          isDuplicateError = true
        }
        this.emitChange()
        break
      case Constants.ActionTypes.CUSTOMER_USER_ROLE_CREATED:
        if (customersLoaded) {
          const idCompany = action.createdUserRole.constructionCompany
          const addUsersAmount = 1
          this.updateUsersAmountCompany(idCompany, addUsersAmount)
        }
        this.emitChange()
        break
    }
  })
}

export default new CompaniesPanelStore()
