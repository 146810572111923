import SentryLogger from './SentryLogger'
import ConsoleLogger from './ConsoleLogger'
import IClientLogInterface = AppConfig.IClientLogInterface

// @ts-ignore comes from local.js
const config: IClientLogInterface = CLIENT_LOG_CONFIG

// @ts-ignore comes from local.js
const version: string = VERSION

export interface ILogger {
  log(message: string, payload?: object): void

  info(message: string, payload?: object): void

  warn(message: string, payload?: object): void

  error(message: string, payload?: object): void

  exception(exception: Error, payload?: object): void

  failedRequest(message: string, request: any, response: any, payload?: object): void

  setUserContext({ email }: { email: string }): void

  setExtraContext(contextData: object): void

  captureBreadcrumb(message: string, data?: object): void
}

class LoggersFacade implements ILogger {
  loggers: ILogger[]
  sessionId: string
  constructor() {
    this.sessionId = sessionId()
    this.loggers = [ConsoleLogger.create()]

    if (config.sentry.enabled) {
      this.loggers.push(SentryLogger.create({ ...config.sentry, release: version, sessionId: this.sessionId }))
    }
  }

  _eachLoggerCall(method: string, args: any[]) {
    this.loggers.forEach(logger => {
      try {
        logger[method](...args)
      } catch (err) {
        console.error(`Failed to call "${method}" on "${logger}"`)
      }
    })
  }

  log(message: string, payload?: object) {
    this._eachLoggerCall('log', [message, payload])
  }

  info(message: string, payload?: object) {
    this._eachLoggerCall('info', [message, payload])
  }

  warn(message: string, payload?: object) {
    this._eachLoggerCall('warn', [message, payload])
  }

  error(message: string, payload?: object) {
    this._eachLoggerCall('error', [message, payload])
  }

  exception(exception: Error, payload?: object) {
    this._eachLoggerCall('exception', [exception, payload])
  }

  failedRequest(message: string, request: any, response: any, payload?: object) {
    this._eachLoggerCall('failedRequest', [message, request, response, payload])
  }

  setUserContext({ email }: { email: string }) {
    this._eachLoggerCall('setUserContext', [{ email }])
  }

  setExtraContext(contextData: object) {
    this._eachLoggerCall('setExtraContext', [contextData])
  }

  captureBreadcrumb(message: string, data: object) {
    this._eachLoggerCall('captureBreadcrumb', [message, data])
  }
}

const loggerInstance = new LoggersFacade()
export default loggerInstance

function sessionId(length = 8): string {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}
