const ROLES_IN_ORDER = ['MODERATOR', 'MASTER', 'WORKER', 'USER_ADMIN', 'DRONE_PILOT', 'MACHINERY_TRACKING', 'VIEWER']

export const rolesSortingFn = (firstRole, secondRole) => {
  const { id: firstRoleId } = firstRole
  const { id: secondRoleId } = secondRole
  const firstRoleIdIndex = ROLES_IN_ORDER.indexOf(firstRoleId)
  const secondRoleIdIndex = ROLES_IN_ORDER.indexOf(secondRoleId)

  // unknown roles should be sorted alphabetically
  if (firstRoleIdIndex === -1 && secondRoleIdIndex === -1) {
    return firstRoleId.localeCompare(secondRoleId)
  }

  // roles listed in the ROLES_IN_ORDER should be sorted by index
  if (firstRoleIdIndex !== -1 && secondRoleIdIndex !== -1) {
    return firstRoleIdIndex > secondRoleIdIndex ? 1 : -1
  }

  // unknown roles should always be after those listed in the ROLES_IN_ORDER
  return firstRoleIdIndex > secondRoleIdIndex ? -1 : 1
}

export const hasStarOnCustomers = user => {
  return user.roles.customers.some(c => c.customer === '*')
}

export const hasStarOnCustomersAndSites = user => {
  return hasStarOnCustomer(user, '*')
}

export const hasStarOnCustomersButNotSites = user => {
  return hasStarOnCustomers(user) && user.roles.customers.length === 1
}

export const hasStarOnCustomer = (user, customerId) => {
  const customerRole = user.roles.customers.find(c => c.customer === customerId)
  return customerRole && customerRole.sites.some(s => s.site === '*')
}

export const getRolesOnStarCustomer = user => {
  const starCustomer = user.roles.customers.find(c => c.customer === '*')
  if (!starCustomer) {
    return []
  }
  return starCustomer.roles
}
export const getRolesOnStarCustomerSite = user => {
  const starCustomer = user.roles.customers.find(c => c.customer === '*')
  if (!starCustomer) {
    return []
  }
  const starSite = starCustomer.sites.find(s => s.site === '*')
  if (!starSite) {
    return []
  }
  return starSite.roles || []
}
