import React from 'react'
import Throbber from '../../Throbber'
import TitledSelect from '../../TitledSelect'

import CompaniesPanelActions from '../actions/CompaniesPanelActionCreators'
import CompaniesPanelStore from '../stores/CompaniesPanelStore'
import { decodeCustomerTypeName } from '../../../libs/customer-type-decoder'
import { classes, style } from 'typestyle'

class CompanyManagementForm extends React.Component {
  static displayName = 'CompanyManagementForm'

  static defaultProps = {
    onCompanyUnSelect: () => {}
  }

  state = {
    editCompany: {
      name: this.props.company.name,
      type: this.props.company.type,
      isAccountHolder: this.props.company.isAccountHolder
    },
    customerType: this.props.company.customerType
  }

  componentDidMount() {
    CompaniesPanelStore.addChangeListener(this.onCompaniesPanelStoreChange)
  }

  componentWillUnmount() {
    CompaniesPanelStore.removeChangeListener(this.onCompaniesPanelStoreChange)
  }

  onCompaniesPanelStoreChange = () => {
    this.setState({ loading: CompaniesPanelStore.getData().loading })
  }

  handleIsAccHolderChanged = e => {
    const editCompany = { ...this.state.editCompany, isAccountHolder: e.target.checked }
    this.setState({ editCompany })
  }

  handleChangeCompanyName = e => {
    const editCompany = { ...this.state.editCompany, name: e.target.value }
    this.setState({ editCompany })
  }

  handleCustomerTypeChange = type => {
    const editCompany = { ...this.state.editCompany, type }
    this.setState({ editCompany })
  }

  updateCompany = e => {
    e.preventDefault()
    if (!this.valid()) {
      return
    }
    CompaniesPanelActions.updateCompany(this.props.company.id, this.state.editCompany)
    this.props.onCompanyUnSelect()
  }

  valid = () => {
    return Boolean(this.state.editCompany.name) && Boolean(this.state.editCompany.type)
  }

  reset = () => {
    this.setState({ fileLoaded: false })
  }

  render() {
    const {
      editCompany: { isAccountHolder: newIsAccountHolder }
    } = this.state
    const {
      company: { isAccountHolder: oldIsAccountHolder }
    } = this.props
    const isAccountHolderStatusChanged = newIsAccountHolder !== oldIsAccountHolder
    return (
      <div>
        <Throbber show={this.state.loading}>
          <h3>Edit company</h3>
          <form className='form-horizontal'>
            <div>
              <label>Name:</label>

              <div>
                <input
                  type='text'
                  readOnly={true}
                  className='form-control'
                  defaultValue={this.state.editCompany.name}
                  value={this.state.editCompany.name}
                  onChange={this.handleChangeCompanyName}
                />
              </div>
            </div>
            <br />
            <TitledSelect
              title='Select company type'
              onChange={this.handleCustomerTypeChange}
              value={this.state.editCompany.type}
            >
              {this.props.customerTypes.map(ct => (
                <option value={ct.key} key={ct.key}>
                  {decodeCustomerTypeName(ct.key)}
                </option>
              ))}
            </TitledSelect>
            <br />
            <div className='checkbox'>
              <label>
                <input
                  disabled={this.props.company.isAccountHolder}
                  type='checkbox'
                  checked={this.state.editCompany.isAccountHolder}
                  onChange={this.handleIsAccHolderChanged}
                />
                Is Account Holder
              </label>
            </div>
            {isAccountHolderStatusChanged && (
              <div className={classes('alert alert-warning', style({ marginTop: 20 }))}>
                You are going to promote this company to "Account Holder". If later you may want to switch its status
                back, ask someone from the Developers team to do that.
              </div>
            )}
            <br />
            <button type='submit' className='btn btn-primary' disabled={!this.valid()} onClick={this.updateCompany}>
              Update company
            </button>
          </form>
        </Throbber>
      </div>
    )
  }
}

export default CompanyManagementForm
