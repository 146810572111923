import * as keyMirror from 'key-mirror'
import { PERMISSIONS } from './permissions'
import PERMISSIONS_LEVELS from './permissions_levels'

const Constants = {
  LOCALES: ['en', 'ru'],
  DEFAULT_LOCALE: 'en',
  localStorage: keyMirror({
    language: null
  }),
  ACCESS_TOKEN_RENEWAL_TIME_RESERVE: 5 * 60 * 1000,
  ACCESS_TOKEN_MAX_ERROR_COUNT: 20,
  ACCESS_TOKEN_RENEWAL_AFTER_ERROR_TIME: 1000,

  PERMISSIONS,
  PERMISSIONS_LEVELS,
  roles: keyMirror({
    MODERATOR: null,
    MASTER: null,
    WORKER: null
  }),
  CHANGE_EVENT: 'change',

  LOGOUT_EVENT: 'logout',
  LOGIN_SUCCESS_EVENT: 'login-success',
  PASSWORD_UPDATE_FAILED_EVENT: 'password-update-failed',
  PASSWORD_UPDATE_EVENT: 'password-updated',

  ActionTypes: keyMirror({
    LOGIN_ATTEMPT: null,
    LOGIN_FAILED: null,
    LOGIN_SUCCESS: null,
    RECOVERY_PASSWORD_ATTEMPT: null,
    RECOVERY_PASSWORD_FAILED: null,
    RECOVERY_PASSWORD_SUCCESS: null,

    USER_PASSWORD_UPDATE_FAILED: null,

    ACCESS_TOKEN_RECEIVED: null,

    FETCHING_USERS: null,
    FETCHED_USERS: null,
    FETCHING_USER_ROLES: null,
    FETCHED_USER_ROLES: null,
    FETCHING_CUSTOMER_TYPES: null,
    FETCHED_CUSTOMER_TYPES: null,
    FETCHING_CUSTOMER_SITES: null,
    USERS_PANEL_SELECTED_USER_CLEAR: null,
    FETCHED_CUSTOMER_SITES: null,
    CUSTOMER_ADD_ACCESS: null,
    CUSTOMER_USER_ROLE_CREATED: null,
    SITE_USER_ROLE_CREATION: null,
    SITE_USER_ROLE_CREATED: null,
    SITE_USER_ROLE_DELETED: null,
    SITE_USER_ROLE_POLICIES_UPDATED: null,
    CUSTOMER_EMPTY_REMOVED: null,

    ALL_SITES_STAR_ROLE_UPDATED: null,

    USER_CREATION_STARTED: null,
    USER_CREATED: null,
    USER_CREATE_FAILED: null,
    USER_CREATION_CLOSED: null,
    USER_UPDATING: null,
    USER_UPDATED: null,
    USER_DELETED: null,
    USER_PASSWORD_UPDATED: null,
    APP_USER_UPDATING: null,
    APP_USER_UPDATED: null,

    FETCH_ROLES_AND_PERMISSIONS: null,

    RENAMINGS_FETCHING: null,
    RENAMINGS_FETCHED: null
  }),

  ActionSources: keyMirror({
    SERVER_ACTION: null,
    VIEW_ACTION: null
  })
}

Constants.ActionTypes.companiesPanelActions = keyMirror({
  FETCH_COMPANIES_WITH_COUNT_USERS: null,
  CUSTOMER_TYPES_FETCHED: null,
  COMPANY_CREATION_STARTED: null,
  COMPANY_CREATED: null,
  COMPANY_UPDATING: null,
  COMPANY_UPDATED: null,
  COMPANY_SWAPPED: null
})

export default Constants
