import * as React from 'react'
import RolesPanelStore from '../stores/RolesPanelStore.js'
import RolesPanelActionCreators from '../actions/RolesPanelActionCreators.js'
import { roleCellCheckboxStyle, roleCellLabelStyle, roleTitleStyle, tableStyle } from './roles_table/SitesRolesTable'
import { getRolesOnStarCustomerSite } from '../../../libs/roles'
import Throbber from '../../Throbber'
import { style } from 'typestyle'
import { isTAUser } from '../../../libs/users'

type AllCustomersStarRolesPanelProps = {
  user: User
}

type AllCustomersStarRolesPanelState = {
  roles: Role[]
  pending: boolean
}
export default class AllCustomersStarRolesPanel extends React.Component<
  AllCustomersStarRolesPanelProps,
  AllCustomersStarRolesPanelState
> {
  constructor(props) {
    super(props)
    this.state = { pending: false, ...this.getStoreState() }
  }

  componentDidMount() {
    RolesPanelStore.addChangeListener(this.handleStoreChanged)
  }

  componentWillUnmount() {
    RolesPanelStore.removeChangeListener(this.handleStoreChanged)
  }

  getStoreState = () => {
    const { roles } = RolesPanelStore.getData()
    return { roles }
  }

  handleStoreChanged = () => {
    this.setState(this.getStoreState())
  }

  handleRoleCheckboxChanged = async (isChecked: boolean, roleName: string) => {
    this.setState({ pending: true })
    try {
      if (isChecked) {
        await RolesPanelActionCreators.assignStarRoleOnAllSites(this.props.user.email, roleName)
      } else {
        await RolesPanelActionCreators.revokeStarRoleOnAllSites(this.props.user.email, roleName)
      }
    } finally {
      this.setState({ pending: false })
    }
  }

  render() {
    const { roles, pending } = this.state
    const { user } = this.props
    const starRoles = getRolesOnStarCustomerSite(user)
    const taUser = isTAUser(user)
    return (
      <>
        <h3>Grant access to all customers and sites with roles (available only for TA employees):</h3>
        <Throbber show={pending} mini>
          <table className={`table table-condensed table-striped ${tableStyle} ${allStarsTableStyle}`}>
            <thead>
              <tr style={{ background: '#CCE6FF' }}>
                <th />
                {roles.map(role => {
                  return (
                    <th key={role.id}>
                      <label className={roleCellLabelStyle}>
                        <input
                          type='checkbox'
                          disabled={!taUser}
                          className={roleCellCheckboxStyle}
                          checked={starRoles.some(r => r === role.id)}
                          onChange={e => this.handleRoleCheckboxChanged(e.target.checked, role.id)}
                        />
                        <span className={roleTitleStyle}>{role.id}</span>
                      </label>
                    </th>
                  )
                })}
              </tr>
            </thead>
          </table>
        </Throbber>
      </>
    )
  }
}

export const allStarsTableStyle = style({
  $nest: {
    '& > thead > tr': {
      background: '#ffd7c3 !important'
    },
    '& > thead > tr > th': {
      verticalAlign: 'middle',
      padding: '12px 0'
    },
    '& > thead label': {
      margin: 0
    }
  }
})
