import PropTypes from 'prop-types'
import React from 'react'
import RolesPanelActions from '../actions/RolesPanelActionCreators'

class RemoveUserButton extends React.Component {
  static displayName = 'RemoveUserButton'

  static propTypes = {
    user: PropTypes.object
  }

  removeUser = e => {
    e.preventDefault()
    if (confirm('Are you sure?')) {
      RolesPanelActions.removeUser(this.props.user.email)
    }
  }

  render() {
    return (
      <div>
        <button className='btn btn-danger remove-user-button' onClick={this.removeUser}>
          Remove user
        </button>
      </div>
    )
  }
}

export default RemoveUserButton
