import * as React from 'react'
import { IRenaming, IRenamingLog } from '../RenamingsPanelStore'

interface IRenamingCardProps {
  renaming: IRenaming
  filter: string
}

export default class RenamingCard extends React.Component<IRenamingCardProps, {}> {
  render() {
    const { renaming, filter } = this.props
    return (
      <div className='renaming-card panel panel-default' style={{ maxWidth: 800 }}>
        <div className='panel-heading' style={{ display: 'flex', flexDirection: 'column' }}>
          <span>
            {renaming.customerName} {renaming.siteName && `/ ${renaming.siteName}`}
          </span>
          <span style={{ fontWeight: 'bold' }}>
            {renaming.customerId} {renaming.siteId && `/ ${renaming.siteId}`}
          </span>
        </div>
        <div className='panel-body'>{this.renderLog(renaming.log, filter)}</div>
      </div>
    )
  }

  renderLog(renamingsLog: IRenamingLog[], filter: string) {
    return (
      <ul className='list-group'>
        {renamingsLog
          .map(r => {
            const isSite = !!r.oldSiteName
            const newNameField = isSite ? 'newSiteName' : 'newCustomerName'
            const oldNameField = isSite ? 'oldSiteName' : 'oldCustomerName'
            const newName = r[newNameField] as string
            const oldName = r[oldNameField] as string
            return (
              <li className='list-group-item' key={oldName + newName}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div>
                    {highlightFilter(oldName, filter)} -{'>'} {highlightFilter(newName, filter)}
                  </div>
                  <div>
                    {new Date(r.renamedAt).toLocaleString()} / {r.author}
                  </div>
                </div>
              </li>
            )
          })
          .reverse()}
      </ul>
    )
  }
}

function highlightFilter(name: string, filter: string) {
  if (filter.length === 0) {
    return name
  }
  const lcName = name.toLowerCase()
  const index = lcName.indexOf(filter.toLowerCase())

  if (index === -1) {
    return name
  }

  const prefix = name.slice(0, index)
  const filterStr = name.slice(index, index + filter.length)
  const suffix = name.slice(index + filter.length)

  return (
    <span>
      {prefix}
      <span style={{ background: 'yellow' }}>{filterStr}</span>
      {suffix}
    </span>
  )
}
