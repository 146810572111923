import React from 'react'
import Throbber from '../../Throbber'
import UserRolesTable from './UserRolesTable'
import NewUserRoleForm from './NewUserRoleForm'
import RemoveUserButton from './RemoveUserButton'
import UserManagementForm from '../../UserManagementForm'
import RolesPanelActions from '../actions/RolesPanelActionCreators'
import RolesPanelStore from '../stores/RolesPanelStore'
import ResendActivationMailForm from './ResendActivationMailForm'
import { hasStarOnCustomers } from '../../../libs/roles'
import AllCustomersStarRolesPanel from './AllCustomersStarRolesPanel'

class UserManagementPanel extends React.Component {
  static displayName = 'UserManagementPanel'

  state = {
    userUpdating: false,
    newPass: ''
  }

  getCompaniesAvailableForUser = () => {
    return this.props.companies.filter(c => {
      return c.isAccountHolder && !this.props.user.roles.customers.some(role => role.customer === c.id)
    })
  }

  componentDidMount() {
    RolesPanelStore.addChangeListener(this.onRolesPanelStoreChange)
  }

  componentWillUnmount() {
    RolesPanelStore.removeChangeListener(this.onRolesPanelStoreChange)
  }

  onRolesPanelStoreChange = () => {
    const { userUpdating, newlyCreatedCustomerRole } = RolesPanelStore.getData()
    this.setState({ userUpdating, newlyCreatedCustomerRole })
  }

  onUserUpdate = editUser => {
    RolesPanelActions.updateUser(this.props.user.email, editUser)
  }

  onUserPassUpdate = newPass => {
    RolesPanelActions.updateUserPass(this.props.user.email, newPass)
  }

  render() {
    const { user, roles } = this.props
    const starOnCustomers = hasStarOnCustomers(user)
    const { newlyCreatedCustomerRole } = this.state
    const newUserRoleForm = (
      <Throbber show={this.state.userUpdating}>
        <div className='row'>
          <h3>New Account Holder</h3>
          <NewUserRoleForm user={user} roles={roles} companies={this.getCompaniesAvailableForUser()} />
        </div>
      </Throbber>
    )

    const currentUserRoles = user.roles.customers.length > 0 && (
      <div className='row'>
        <h3>Current user roles</h3>
        <Throbber show={this.state.userUpdating}>
          <UserRolesTable
            user={user}
            roles={roles}
            companies={this.props.companies}
            newlyCreatedCustomerRole={newlyCreatedCustomerRole}
          />
        </Throbber>
      </div>
    )

    const userManagementForm = (
      <div className='row'>
        <h3>User management</h3>
        <Throbber show={this.state.userUpdating}>
          <UserManagementForm
            key={user.email}
            user={user}
            companies={this.props.companies}
            customerTypes={this.props.customerTypes}
            onUserUpdate={this.onUserUpdate}
            onUserPassUpdate={this.onUserPassUpdate}
          />
        </Throbber>
      </div>
    )
    const resendActionMailForm = (
      <div className='row'>
        <ResendActivationMailForm user={user} />
      </div>
    )

    const removeUserButton = (
      <div className='row'>
        <RemoveUserButton user={user} />
      </div>
    )

    return (
      <div className='row user-management-panel'>
        <div className='col-xs-12'>
          <AllCustomersStarRolesPanel user={user} />
          {currentUserRoles}
          {!starOnCustomers && newUserRoleForm}
          {userManagementForm}
          {resendActionMailForm}
          {removeUserButton}
        </div>
      </div>
    )
  }
}

export default UserManagementPanel
