import PropTypes from 'prop-types'
import React from 'react'
import i18n from 'i18n'
import TitledSelect from './TitledSelect'
import { Alert } from 'react-bootstrap'
import { decodeTitle } from '../libs/customer-type-decoder'

import CompanySelectionInput from './CompanySelectionInput'
import trackAction from '../libs/analytics/ActionTracking'

class UserManagementForm extends React.Component {
  static displayName = 'UserManagementForm'

  static propTypes = {
    onUserUpdate: PropTypes.func,
    onUserPassUpdate: PropTypes.func,
    user: PropTypes.object,
    companies: PropTypes.array,
    customerTypes: PropTypes.array
  }

  static defaultProps = {
    onUserUpdate: editUser => {
      return editUser
    },
    onUserPassUpdate: newPass => {
      return newPass
    }
  }

  state = {
    newPass: '',
    isResetPass: false,
    editUser: {
      name: this.props.user.name,
      lastname: this.props.user.lastname,
      phone: this.props.user.phone,
      lang: this.props.user.lang,
      company: this.props.user.company,
      title: this.props.user.title,
      titleDescription: this.props.user.titleDescription || ''
    }
  }

  onEditUserChange = (field, e) => {
    var editUser = this.state.editUser
    editUser[field] = e.target.value
    this.forceUpdate()
  }

  handleEditUserChangeCompany = ({ id: companyId }) => {
    if (companyId) {
      const company = _.find(this.props.companies, c => c.id === companyId)
      this.state.editUser.company = { _id: company.id, id: company.id, name: company.name }
    } else {
      this.state.editUser.company = null
    }

    this.forceUpdate()
  }

  handleEditUserChangeTitle = value => {
    this.state.editUser.title = value
    this.forceUpdate()
  }

  onTraceairEmployeeChange = e => {
    this.state.editUser.traceairEmployee = e.target.checked
    this.forceUpdate()
  }

  updateUser = e => {
    e.preventDefault()
    this.props.onUserUpdate({
      ...this.state.editUser,
      company: this.state.editUser.company ? this.getUserCompany().id : null
    })
    this.setState({ isResetPass: false })
  }

  updatePassword = e => {
    e.preventDefault()
    trackAction('Set password button is clicked')
    this.setState({ isResetPass: false })
    var newPass = this.state.newPass
    if (!newPass || newPass.length < 8) {
      return
    }
    this.props.onUserPassUpdate(newPass)
    this.setState({ newPass: '', isResetPass: true })
  }

  getUserCompany() {
    return _.find(
      this.props.companies,
      c => c.id === this.state.editUser.company.id || c.id === this.state.editUser.company.id
    )
  }
  getTitlesForCompanyType = () => {
    let customerType
    if (this.state.editUser.company) {
      customerType = this.getUserCompany().type
    }

    if (!customerType) {
      return []
    }

    const customerTypeObject = this.props.customerTypes.filter(ct => ct.key === customerType)[0]
    if (!customerTypeObject) {
      return []
    }
    return customerTypeObject.titles
  }

  render() {
    if (this.state.isResetPass) {
      var resetPasswordSuccessAlert = (
        <Alert bsStyle='success'>{i18n.getIntlMessage('profile-form.set-password-successful')}</Alert>
      )
    }
    const titles = this.getTitlesForCompanyType()

    return (
      <div>
        {resetPasswordSuccessAlert}
        <form className='form-horizontal'>
          <div className='form-group'>
            <label className='col-xs-2 user-management-form-label'>{i18n.getIntlMessage('profile-form.name')}:</label>

            <div className='col-xs-8'>
              <input
                type='text'
                className='form-control'
                value={this.state.editUser.name}
                onChange={this.onEditUserChange.bind(this, 'name')}
              />
            </div>
          </div>
          <div className='form-group'>
            <label className='col-xs-2 user-management-form-label'>
              {i18n.getIntlMessage('profile-form.last-name')}:
            </label>

            <div className='col-xs-8'>
              <input
                type='text'
                className='form-control'
                value={this.state.editUser.lastname}
                onChange={this.onEditUserChange.bind(this, 'lastname')}
              />
            </div>
          </div>

          {
            <div className='form-group'>
              <label className='col-xs-2 user-management-form-label'>Company:</label>
              <div className='col-xs-8'>
                <CompanySelectionInput
                  onChange={this.handleEditUserChangeCompany}
                  companyId={this.state.editUser.company && this.state.editUser.company.id}
                  companies={this.props.companies}
                />
              </div>
            </div>
          }

          {titles.length > 0 && (
            <div className='form-group'>
              <label className='col-xs-2 user-management-form-label'>Title:</label>

              <div className='col-xs-8'>
                <TitledSelect
                  type='text'
                  className='form-control'
                  title='Select title...'
                  value={this.state.editUser.title}
                  onChange={this.handleEditUserChangeTitle}
                >
                  {titles.map(title => (
                    <option key={title} value={title}>
                      {decodeTitle(title)}
                    </option>
                  ))}
                </TitledSelect>
              </div>
            </div>
          )}

          {titles.length > 0 && (
            <div className='form-group'>
              <label className='col-xs-2 user-management-form-label'>Title description:</label>
              <div className='col-xs-8'>
                <input
                  type='text'
                  className='form-control'
                  value={this.state.editUser.titleDescription}
                  onChange={this.onEditUserChange.bind(this, 'titleDescription')}
                />
              </div>
            </div>
          )}

          <div className='form-group'>
            <label className='col-xs-2 user-management-form-label'>{i18n.getIntlMessage('profile-form.phone')}:</label>

            <div className='col-xs-8'>
              <input
                type='text'
                className='form-control'
                value={this.state.editUser.phone}
                onChange={this.onEditUserChange.bind(this, 'phone')}
              />
            </div>
          </div>

          <div className='clearfix form-actions'>
            <button type='submit' className='btn btn-primary' onClick={this.updateUser}>
              {i18n.getIntlMessage('profile-form.update')}
            </button>
          </div>
        </form>

        <form className='form-inline password-reset-form'>
          <div className='form-group'>
            <label>{i18n.getIntlMessage('profile-form.reset-pass')}:</label>
            <input
              type='password'
              className='form-control'
              value={this.state.newPass}
              onChange={e => this.setState({ newPass: e.target.value })}
            />
            <button className='btn btn-warning' disabled={this.state.newPass.length < 8} onClick={this.updatePassword}>
              {i18n.getIntlMessage('profile-form.set-pass')}
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default UserManagementForm
