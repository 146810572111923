import Dispatcher from '../../../Dispatcher'
import $ from '../../../WebUtils'
import Constants from '../../../Constants'
import async from 'async'

export default {
  fetchRolesInfo() {
    Dispatcher.handleViewAction({ type: Constants.ActionTypes.FETCHING_USERS })
    async.parallel([$.getUsers, $.getCustomers, $.getRoles], (err, responses) => {
      if (err) {
        return console.error(err)
      }

      const users = responses[0].body.users
      const companies = responses[1].body.customers
      const roles = responses[2].body.roles
      Dispatcher.handleServerAction({ type: Constants.ActionTypes.FETCHED_USERS, users, companies, roles })
    })
  },

  async fetchCustomerSites(customerId) {
    Dispatcher.handleViewAction({ type: Constants.ActionTypes.FETCHING_CUSTOMER_SITES })
    return new Promise(resolve => {
      $.getAccessToken(customerId, { permissions: ['CUSTOMER_VIEW'] }, async (err, tokenResponse) => {
        if (err) {
          return alert('Failed to obtain a token: ' + err.message)
        }
        const { accessToken } = tokenResponse.body
        await _fetchCustomerSites(customerId, accessToken)
        resolve()
      })
    })
  },
  async getUserRolesAndSitePolicies(email, customerId) {
    Dispatcher.handleViewAction({ type: Constants.ActionTypes.FETCHING_USER_ROLES })
    return new Promise((resolve, reject) => {
      $.getAccessToken(
        customerId,
        { permissions: ['CSU_API_SITE_USER_ROLE_POLICY_LIST'] },
        async (err, tokenResponse) => {
          if (err) {
            return reject(new Error('Failed to obtain a token: ' + err.message))
          }
          const { accessToken } = tokenResponse.body
          const [rolesRes, policiesRes] = await Promise.all([
            $.getUserRoles(email),
            $.listSiteUserRolePolicy(email, customerId, accessToken)
          ])
          Dispatcher.handleServerAction({
            type: Constants.ActionTypes.FETCHED_USER_ROLES,
            email,
            roles: rolesRes.body.roles,
            policies: policiesRes.body.policies
          })
        }
      )
    })
  },
  getCustomersTypes() {
    Dispatcher.handleViewAction({ type: Constants.ActionTypes.FETCHING_CUSTOMER_TYPES })
    $.getCustomersTypes().then(
      res => {
        Dispatcher.handleServerAction({
          type: Constants.ActionTypes.FETCHED_CUSTOMER_TYPES,
          customerTypes: res.body.customerTypes
        })
      },
      err => {
        alert(err.message)
      }
    )
  },

  addCustomerAccess({ email, customerId }) {
    Dispatcher.handleViewAction({
      type: Constants.ActionTypes.CUSTOMER_ADD_ACCESS
    })
    $.getAccessToken(
      customerId,
      {
        permissions: ['CUSTOMER_VIEW', 'SITE_VIEW', 'CSU_API_USER_ROLE_ASSIGN_SITE']
      },
      async (err, tokenResponse) => {
        if (err) {
          return alert('Failed to obtain a token: ' + err.message)
        }
        const { accessToken } = tokenResponse.body
        await _fetchCustomerSites(customerId, accessToken)

        Dispatcher.handleServerAction({
          type: Constants.ActionTypes.CUSTOMER_USER_ROLE_CREATED,
          email,
          role: { customer: customerId, roles: [], sites: [] }
        })
      }
    )
  },
  createSiteUserRole({ email, customerId, siteId, roleName }) {
    return new Promise((resolve, reject) => {
      $.getAccessToken(customerId, { site: siteId }, (err, tokenResponse) => {
        if (err) {
          return reject(new Error('Failed to obtain a token: ' + err.message))
        }
        const { accessToken } = tokenResponse.body
        $.addSiteUserRole(
          { email, customerId, siteId, roleName },
          (err, response) => {
            if (err) {
              return reject(new Error('Failed to assign role ' + err.message))
            }
            Dispatcher.handleServerAction({
              type: Constants.ActionTypes.SITE_USER_ROLE_CREATED,
              email,
              role: response.body.roles.customers[0]
            })
            resolve()
          },
          accessToken
        )
      })
    })
  },
  deleteSiteUserRole({ email, customerId, siteId, roleName }) {
    return new Promise((resolve, reject) => {
      $.getAccessToken(customerId, { site: siteId }, (err, tokenResponse) => {
        if (err) {
          return reject(new Error('Failed to obtain a token: ' + err.message))
        }
        const { accessToken } = tokenResponse.body
        $.removeSiteUserRole(
          { email, customerId, siteId, roleName },
          (err, response) => {
            if (err) {
              return reject(new Error('Failed to remove role ' + err.message))
            }
            Dispatcher.handleServerAction({
              type: Constants.ActionTypes.SITE_USER_ROLE_DELETED,
              email,
              role: response.body.roles.customers[0]
            })
            resolve()
          },
          accessToken
        )
      })
    })
  },
  async deleteSitesUserRoles({ email, customerId, sitesRoles }) {
    const roles = await Promise.all(
      sitesRoles.map(siteRole => {
        return new Promise((resolve, reject) => {
          $.getAccessToken(customerId, { site: siteRole.site }, async (err, tokenResponse) => {
            if (err) {
              alert('Failed to obtain a token: ' + err.message)
              return reject(err.message)
            }
            const { accessToken } = tokenResponse.body

            try {
              $.removeSiteUserRole(
                { email, customerId, siteId: siteRole.site, roleName: siteRole.role },
                (err, res) => {
                  if (err) {
                    return reject(err)
                  }
                  resolve(res.body.roles.customers[0])
                },
                accessToken
              )
            } catch (err) {
              alert('Failed to revoke roles: ' + err.message)
              reject(err.message)
            }
          })
        })
      })
    )

    roles.forEach(role => {
      Dispatcher.handleServerAction({
        type: Constants.ActionTypes.SITE_USER_ROLE_DELETED,
        email,
        role
      })
    })
  },
  clearEmptyCustomerRoles({ email, customer }) {
    Dispatcher.handleServerAction({ type: Constants.ActionTypes.CUSTOMER_EMPTY_REMOVED, email, customer })
  },
  clearSelectedUser() {
    Dispatcher.handleViewAction({ type: Constants.ActionTypes.USERS_PANEL_SELECTED_USER_CLEAR })
  },
  updateUser(email, userData) {
    Dispatcher.handleViewAction({ type: Constants.ActionTypes.USER_UPDATING })
    $.updateUser(email, userData, (err, response) => {
      Dispatcher.handleServerAction({ type: Constants.ActionTypes.USER_UPDATED, user: response.body })
    })
  },
  createUser(userData, siteUserPolicyRoles) {
    Dispatcher.handleServerAction({ type: Constants.ActionTypes.USER_CREATION_STARTED })

    const customer = userData.roles.customers[0].customer
    const site = userData.roles.customers[0].sites[0].site
    $.getAccessToken(
      customer,
      {
        permissions: [
          'CSU_LOGIN_USER_REGISTER',
          'CSU_LOGIN_USER_REGISTER_SITE',
          'CSU_API_USER_ROLE_ASSIGN_SITE',
          'CSU_API_USER_ROLE_ASSIGN_CUSTOMER',
          'CSU_API_USER_ROLE_ASSIGN_SYSTEM'
        ]
      },
      (err, res) => {
        if (err) {
          return console.error(err)
        }

        var jwt = res.body.accessToken
        $.registerUser(
          userData,
          customer,
          site,
          async (err, res) => {
            if (err || res.error) {
              return Dispatcher.handleServerAction({
                type: Constants.ActionTypes.USER_CREATE_FAILED,
                error: err.response.text || err
              })
            }

            const user = res.body.user

            Dispatcher.handleServerAction({
              type: Constants.ActionTypes.USER_CREATED,
              user,
              roles: res.body.roles
            })

            await Promise.all(
              siteUserPolicyRoles.map(role => this.assignSiteUserRolePolicy(user.email, customer, role))
            )
          },
          jwt
        )
      }
    )
  },
  closeUserCreation() {
    Dispatcher.handleViewAction({ type: Constants.ActionTypes.USER_CREATION_CLOSED })
  },
  updateUserPass(email, pass) {
    $.updatePassword(email, pass, err => {
      if (err) {
        return console.error(err)
      }
      Dispatcher.handleServerAction({ type: Constants.ActionTypes.USER_PASSWORD_UPDATED })
    })
  },
  removeUser(email) {
    $.removeUser(email, err => {
      if (err) {
        return console.error(err)
      }
      Dispatcher.handleServerAction({ type: Constants.ActionTypes.USER_DELETED, email })
    })
  },
  resendWelcomeMail(email, customerId, siteId, cb) {
    $.getAccessToken(customerId, { permissions: ['CSU_LOGIN_USER_REGISTER_SITE'], site: siteId }, (err, res) => {
      if (err) {
        return cb(err.response ? err.response.body : err)
      }

      const jwt = res.body.accessToken
      $.resendWelcomeMail(
        {
          email,
          customerId,
          siteId
        },
        (err, res) => {
          if (err || res.error) {
            return cb(err.response ? err.response.body : err)
          }

          return cb(null)
        },
        jwt
      )
    })
  },
  async assignSiteUserRolePolicy(email, customerId, roleName) {
    return new Promise((resolve, reject) => {
      $.getAccessToken(customerId, { permissions: ['CSU_API_SITE_USER_ROLE_POLICY_ASSIGN'] }, async (err, jwtRes) => {
        try {
          if (err) {
            throw new Error(`Failed to get token: ${err.response ? err.response.body : err}`)
          }

          const jwt = jwtRes.body.accessToken

          const res = await $.assignSiteUserRolePolicy(email, customerId, roleName, jwt)
          Dispatcher.handleServerAction({
            type: Constants.ActionTypes.SITE_USER_ROLE_POLICIES_UPDATED,
            email,
            policies: res.body.policies
          })
          resolve()
        } catch (err) {
          reject(err)
          console.error(err)
          alert('Failed to update user policies ' + err.message)
        }
      })
    })
  },
  async revokeSiteUserRolePolicy(email, customerId, roleName) {
    return new Promise((resolve, reject) => {
      $.getAccessToken(customerId, { permissions: ['CSU_API_SITE_USER_ROLE_POLICY_REVOKE'] }, async (err, jwtRes) => {
        try {
          if (err) {
            throw new Error(`Failed to get token: ${err.response ? err.response.body : err}`)
          }

          const jwt = jwtRes.body.accessToken

          const res = await $.revokeSiteUserRolePolicy(email, customerId, roleName, jwt)
          Dispatcher.handleServerAction({
            type: Constants.ActionTypes.SITE_USER_ROLE_POLICIES_UPDATED,
            email,
            policies: res.body.policies
          })
          resolve()
        } catch (err) {
          reject(err)
          console.error(err)
          alert('Failed to update user policies ' + err.message)
        }
      })
    })
  },
  async assignStarRoleOnAllSites(email, roleName) {
    try {
      const res = await $.assignStarRoleOnAllSites(email, roleName)
      const { roles } = res.body
      Dispatcher.handleServerAction({
        type: Constants.ActionTypes.ALL_SITES_STAR_ROLE_UPDATED,
        email,
        roles
      })
    } catch (err) {
      console.error(err)
      alert('Failed to assign star role: ' + err.message)
    }
  },

  async revokeStarRoleOnAllSites(email, roleName) {
    try {
      const res = await $.revokeStarRoleOnAllSites(email, roleName)
      const { roles } = res.body
      Dispatcher.handleServerAction({
        type: Constants.ActionTypes.ALL_SITES_STAR_ROLE_UPDATED,
        email,
        roles
      })
    } catch (err) {
      console.error(err)
      alert('Failed to revoke star role: ' + err.message)
    }
  }
}

function _fetchCustomerSites(customerId, accessToken) {
  return new Promise(resolve => {
    $.getSites(
      customerId,
      (err, res) => {
        Dispatcher.handleServerAction({
          type: Constants.ActionTypes.FETCHED_CUSTOMER_SITES,
          customerId,
          sites: res.body.sites
        })
        resolve()
      },
      accessToken
    )
  })
}
