import * as _ from 'lodash'
import BaseStore from './BaseStore'
import AppDispatcher from '../Dispatcher'
import Constants from '../Constants'
import { isTAUser } from '../libs/users'

let userInfo: User
let initialLoading = true
let loading = false
let error = false
let isRecoveryPasswordSuccess = false

class UserStore extends BaseStore {
  getData() {
    return {
      loading: loading,
      error: error,
      userInfo: userInfo,
      isAuth: userInfo && !!userInfo.id,
      initialLoading,
      isRecoveryPasswordSuccess
    }
  }

  updateUser(updatedUser: User) {
    _.assign(userInfo, updatedUser)
  }

  dispatcherIndex = AppDispatcher.register(payload => {
    const action = payload.action

    switch (action.type) {
      case Constants.ActionTypes.LOGIN_ATTEMPT:
        loading = true
        error = false
        this.emitChange()
        break

      case Constants.ActionTypes.LOGIN_FAILED:
        loading = false
        error = action.error
        initialLoading = false
        this.emitChange()
        break

      case Constants.ActionTypes.LOGIN_SUCCESS:
        loading = false
        error = false
        initialLoading = false
        userInfo = action.userInfo
        userInfo.isTraceairDomain = isTAUser(userInfo)
        this.emit(Constants.LOGIN_SUCCESS_EVENT)
        this.emitChange()
        break

      case Constants.ActionTypes.RECOVERY_PASSWORD_ATTEMPT:
        loading = true
        error = false
        initialLoading = false
        this.emitChange()
        break

      case Constants.ActionTypes.RECOVERY_PASSWORD_FAILED:
        loading = false
        error = true
        initialLoading = false
        isRecoveryPasswordSuccess = false
        this.emitChange()
        break

      case Constants.ActionTypes.RECOVERY_PASSWORD_SUCCESS:
        loading = false
        initialLoading = false
        error = false
        isRecoveryPasswordSuccess = true
        this.emitChange()
        break

      case Constants.ActionTypes.APP_USER_UPDATING:
        loading = true
        initialLoading = false
        this.emitChange()
        break

      case Constants.ActionTypes.APP_USER_UPDATED: {
        const updatedUser = action.user
        loading = false
        initialLoading = false
        this.updateUser(updatedUser)
        this.emitChange()
        break
      }

      case Constants.ActionTypes.USER_PASSWORD_UPDATED:
        this.emit(Constants.PASSWORD_UPDATE_EVENT)
        break

      case Constants.ActionTypes.USER_PASSWORD_UPDATE_FAILED:
        this.emit(Constants.PASSWORD_UPDATE_FAILED_EVENT)
        break
    }
  })
}

export default new UserStore()
